import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import 'bootstrap/dist/css/bootstrap.min.css';

import WelcomeComponent from './components/WelcomeComponent';
import Traditional401kFormComponent from './components/Traditional401kFormComponent';
import Traditional401kProfitComponent from './components/Traditional401kProfitComponent';

function App() {
  return (
    <Router>
      <div>
        <Switch>
          <Route path='/' exact component={WelcomeComponent} />
          <Route path='/traditional' exact component={Traditional401kFormComponent} />
          <Route path='/traditional/profit' exact component={Traditional401kProfitComponent} />
        </Switch>
      </div>
    </Router>
  );
}

export default App;
