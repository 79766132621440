import { Component } from 'react';

export interface WindowResizeListenerComponentState {
  width: number,
  height: number,
};

class WindowResizeListenerComponent<PropsType, StateType extends WindowResizeListenerComponentState>
  extends Component<PropsType, StateType> {
  constructor(props: PropsType) {
    super(props);

    this.didResizeWindow = this.didResizeWindow.bind(this);
  }

  componentDidMount() {
    window.addEventListener('resize', this.didResizeWindow);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.didResizeWindow);
  }

  private didResizeWindow() {
    this.setState({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  }
}

export default WindowResizeListenerComponent;