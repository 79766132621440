import React, { CSSProperties } from 'react';

import { Link } from 'react-router-dom';
import CardGroup from 'react-bootstrap/CardGroup';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';

import { AppFont, isLargeWindowWidth } from '../common';
import WindowResizeListenerComponent, { WindowResizeListenerComponentState } from './WindowResizeListenerComponent';

/** Header title. */
const welcomeHeaderTitle: string = '401(k) Calculator';
/** Header subtitle. */
const welcomeHeaderSubtitle: string = 'Find out your estimated profit on 401(k) investment, whether you’re saving for retirement or making an early withdrawal due to unforseeable circumstances.';
/** Description on 401(k) card options. */
const welcomeSubheaderTitle: string = 'Calculate profit on ...';
/** Title for Roth IRA 401(k) card. */
const welcomeRothIRA401kCardTitle: string = 'Roth IRA';
/** Description on Roth IRA 401(k). */
const welcomeRothIRA401kCardText: string = 'A Roth IRA is an individual retirement account that offers tax-free growth and tax-free withdrawals in retirement.';
/** Title for button in Roth IRA 401(k) card. */
const welcomeRothIRA401kCardButtonTitle: string = 'Coming soon ...';
/** Title for traditional 401(k) card. */
const welcomeTraditional401kCardTitle: string = 'Traditional';
/** Description on traditional 401(k). */
const welcomeTraditional401kCardText: string = 'A traditional 401(k) is an employer-sponsored plan that gives employees a choice of investment options.';
/** Title for button in traditional 401(k) card. */
const welcomeTraditional401kCardButtonTitle: string = 'Calculate Profit';
/** Title for disclaimer. */
const welcomeDisclaimerTitle: string = 'Disclaimer:'
/** Description on disclaimer. */
const welcomeDisclaimerTexts: Array<string> = ['Calculated profit is estimated as it depends on many unpredictable factors, such as annual return, etc.', 'This tool is mainly for educational purposes. It should not be used to make any important financial decisions.'];

/**
 * Component for welcome page, that is, the component for empty path '/'.
 *
 * This component extends from @c WindowResizeListenerComponent due to styling differences for
 * the card group (which contains Traditional 401(k) card and Roth IRA 401(k) card) on a smaller
 * device than a larger device.
 */
class WelcomeComponent extends WindowResizeListenerComponent<
  any, WindowResizeListenerComponentState> {
  constructor(props: any) {
    super(props);

    this.state = {
      width: window.innerWidth,
      height: window.innerHeight,
    };
  }

  render() {
    return (
      <div style={welcomeContainerStyle(this.state.width)}>
        <div style={welcomeHeaderContainerStyle}>
          <div style={welcomeHeaderTitleContainerStyle}>
            <div style={welcomeHeaderTitleStyle}>{welcomeHeaderTitle}</div>
            <div style={welcomeHeaderSubtitleStyle}>{welcomeHeaderSubtitle}</div>
          </div>
          <img src={require('../resources/welcome_header_image.png')} alt='' />
        </div>
        <div style={welcomeSubheaderTitleStyle}>{welcomeSubheaderTitle}</div>
        <CardGroup style={welcomeCardGroupContainerStyle(this.state.width)}>
          <Card style={welcomeTraditional401kCardContainerStyle}>
            <Card.Title style={welcomeTraditional401kCardTitleStyle}>
              {welcomeTraditional401kCardTitle}
            </Card.Title>
            <Card.Text style={welcomeTraditional401kCardTextStyle}>
              {welcomeTraditional401kCardText}
            </Card.Text>
            <Link to='/traditional'>
              <Button style={welcomeTraditional401kCardButtonStyle}>
                {welcomeTraditional401kCardButtonTitle}
              </Button>
            </Link>
          </Card>
          <div style={welcomeCardGroupGapStyle} />
          <Card style={welcomeRothIRA401kCardContainerStyle}>
            <Card.Title style={welcomeRothIRA401kCardTitleStyle}>
              {welcomeRothIRA401kCardTitle}
            </Card.Title>
            <Card.Text style={welcomeRothIRA401kCardTextStyle}>
              {welcomeRothIRA401kCardText}
            </Card.Text>
            <Link to='/'>
              <Button style={welcomeRothIRA401kCardButtonStyle}>
                {welcomeRothIRA401kCardButtonTitle}
              </Button>
            </Link>
          </Card>
        </CardGroup>
        <div style={welcomeDisclaimerContainerStyle}>
          <div style={welcomeDisclaimerTitleStyle}>{welcomeDisclaimerTitle}</div>
          {welcomeDisclaimerTexts.map(text =>
            <div key={text} style={welcomeDisclaimerTextStyle}>{text}</div>)}
        </div>
      </div>
    );
  }
}

/**
 * Style for global container.
 *
 * On a smaller device, content margin should be less significant than on a larger device.
 *
 * @param width Inner width of @c window.
 */
function welcomeContainerStyle(width: number): CSSProperties {
  if (isLargeWindowWidth(width)) {
    return {
      marginLeft: '20%',
      marginRight: '20%',
      marginTop: '5%',
      marginBottom: '5%',
    };
  } else {
    return {
      margin: '5%',
    };
  }
}

const welcomeHeaderContainerStyle: CSSProperties = {
  display: 'flex',
  flexDirection: 'row',
};

const welcomeHeaderTitleContainerStyle: CSSProperties = {
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
  justifyContent: 'center',
};

const welcomeHeaderTitleStyle: CSSProperties = {
  ...AppFont,
  fontSize: '250%',
  fontWeight: 'bold',
};

const welcomeHeaderSubtitleStyle: CSSProperties = {
  ...AppFont,
  fontSize: '90%',
  color: '#888888',
};

const welcomeSubheaderTitleStyle: CSSProperties = {
  ...AppFont,
  fontSize: '150%',
};

/**
 * Style for card group container, which contains Traditional 401(k) card and Roth IRA 401(k) card.
 *
 * On a smaller device, the cards are rendered vertically as opposed to horizontally on a larger
 * device.
 *
 * @param width Inner width of @c window.
 */
function welcomeCardGroupContainerStyle(width: number): CSSProperties {
  if (isLargeWindowWidth(width)) {
    return {
      display: 'flex',
      marginTop: '1em',
      flexDirection: 'row',
    };
  } else {
    return {
      display: 'flex',
      marginTop: '1em',
      flexDirection: 'column',
    };
  }
}

const welcomeCardContainerStyle: CSSProperties = {
  borderRadius: '1em',
  padding: '3%',
};

const welcomeTraditional401kCardContainerStyle: CSSProperties = {
  ...welcomeCardContainerStyle,
  backgroundColor: '#56A2FB'
};

const welcomeCardTitleStyle: CSSProperties = {
  ...AppFont,
  fontWeight: 'bold',
  fontSize: '250%',
};

const welcomeTraditional401kCardTitleStyle: CSSProperties = {
  ...welcomeCardTitleStyle,
  color: '#FAFAFA',
};

const welcomeCardTextStyle: CSSProperties = {
  ...AppFont,
  fontSize: '95%',
};

const welcomeTraditional401kCardTextStyle: CSSProperties = {
  ...welcomeCardTextStyle,
  color: '#EDEDED',
};

const welcomeTraditional401kCardButtonStyle: CSSProperties = {
  ...AppFont,
  color: '#FFFFFF',
  backgroundColor: 'transparent',
  borderWidth: 0,
  padding: 0,
  textDecoration: 'underline',
};

const welcomeRothIRA401kCardContainerStyle: CSSProperties = {
  ...welcomeCardContainerStyle,
  backgroundColor: '#EEEEEE',
};

const welcomeRothIRA401kCardTitleStyle: CSSProperties = {
  ...welcomeCardTitleStyle,
  color: '#666666',
};

const welcomeRothIRA401kCardTextStyle: CSSProperties = {
  ...welcomeCardTextStyle,
  color: '#777777',
};

const welcomeRothIRA401kCardButtonStyle: CSSProperties = {
  ...AppFont,
  color: '#666666',
  backgroundColor: 'transparent',
  borderWidth: 0,
  padding: 0,
};

const welcomeCardGroupGapStyle: CSSProperties = {
  width: '20%',
  height: '20%',
  minHeight: '1em',
};

const welcomeDisclaimerContainerStyle: CSSProperties = {
  marginTop: '5%',
};

const welcomeDisclaimerTitleStyle: CSSProperties = {
  ...AppFont,
  color: '#888888',
  fontSize: '85%',
};

const welcomeDisclaimerTextStyle: CSSProperties = {
  ...AppFont,
  color: '#888888',
  fontSize: '85%',
};

export default WelcomeComponent;