export interface TaxBracket {
  rate: number,
  lower: number,
  upper: number | null,
}

export interface TaxDeduction {
  amount: number,
  lower: number,
  upper: number | null,
}

export interface TaxExemption {
  amount: number,
  lower: number,
  upper: number | null,
}

export interface StateTaxBracket {
  /**
   * Some states allow residents to reduce their taxable income if they applied federal itemized
   * deduction.
   */
  deductionByFederalTax: boolean,
  /** Some states set an upper limit on how much residents can deduct from their taxable income. */
  maximumFederalTaxDeductionForStateTax: number | null | undefined,
  taxBrackets: Array<TaxBracket>,
  standardDeduction: Array<TaxDeduction>,
  personalExemption: Array<TaxExemption>,
}

export interface StateTax {
  /** More often than not, single filers and joint filers have different state tax brackets. */
  single: StateTaxBracket,
  married: StateTaxBracket,
}

export const federalTaxBrackets = {
  single: [
    { rate: 0, lower: 0, upper: 12200, },
    { rate: 10, lower: 0, upper: 9700 },
    { rate: 12, lower: 9700, upper: 39475 },
    { rate: 22, lower: 39475, upper: 84200 },
    { rate: 24, lower: 84200, upper: 160725 },
    { rate: 32, lower: 160725, upper: 204100 },
    { rate: 35, lower: 204100, upper: 510300 },
    { rate: 37, lower: 510300, upper: null },
  ],
  married: [
    { rate: 0, lower: 0, upper: 24400 },
    { rate: 10, lower: 0, upper: 19400 },
    { rate: 12, lower: 19400, upper: 78950 },
    { rate: 22, lower: 78950, upper: 168400 },
    { rate: 24, lower: 168400, upper: 321450 },
    { rate: 32, lower: 321450, upper: 408200 },
    { rate: 35, lower: 408200, upper: 612350 },
    { rate: 37, lower: 612350, upper: null },
  ]
};

export const medicareTaxBrackets = {
  single: [
    { rate: 1.45, lower: 0, upper: 200000 },
    { rate: 2.35, lower: 200000, upper: null },
  ],
  married: [
    { rate: 1.45, lower: 0, upper: 200000 },
    { rate: 2.35, lower: 200000, upper: null },
  ]
};

export const socialSecurityTaxBrackets = {
  single: [
    { rate: 6.2, lower: 0, upper: 132900 },
  ],
  married: [
    { rate: 6.2, lower: 0, upper: 132900 },
  ]
};

export const stateTaxBrackets: Map<string, StateTax> = new Map([
  [
    'AL',
    {
      single: {
        deductionByFederalTax: true,
        maximumFederalTaxDeductionForStateTax: null,
        taxBrackets: [
          { rate: 2, lower: 0, upper: 500 },
          { rate: 4, lower: 500, upper: 3000 },
          { rate: 5, lower: 3000, upper: null },
        ],
        standardDeduction: [
          { amount: 2500, lower: 0, upper: 20499 },
          { amount: 2475, lower: 20500, upper: 20999 },
          { amount: 2450, lower: 21000, upper: 21499 },
          { amount: 2425, lower: 21500, upper: 21999 },
          { amount: 2400, lower: 22000, upper: 22499 },
          { amount: 2375, lower: 22500, upper: 22999 },
          { amount: 2350, lower: 23000, upper: 23499 },
          { amount: 2325, lower: 23500, upper: 23999 },
          { amount: 2300, lower: 24000, upper: 24499 },
          { amount: 2275, lower: 24500, upper: 24999 },
          { amount: 2250, lower: 25000, upper: 25499 },
          { amount: 2225, lower: 25500, upper: 25999 },
          { amount: 2200, lower: 26000, upper: 26499 },
          { amount: 2175, lower: 26500, upper: 26999 },
          { amount: 2150, lower: 27000, upper: 27499 },
          { amount: 2125, lower: 27500, upper: 27999 },
          { amount: 2100, lower: 28000, upper: 28499 },
          { amount: 2075, lower: 28500, upper: 28999 },
          { amount: 2050, lower: 29000, upper: 29499 },
          { amount: 2025, lower: 29500, upper: 29999 },
          { amount: 2000, lower: 30000, upper: null },
        ],
        personalExemption: [
          { amount: 1500, lower: 0, upper: null },
        ],
      },
      married: {
        deductionByFederalTax: true,
        maximumFederalTaxDeductionForStateTax: null,
        taxBrackets: [
          { rate: 2, lower: 0, upper: 1000 },
          { rate: 4, lower: 1000, upper: 6000 },
          { rate: 5, lower: 6000, upper: null },
        ],
        standardDeduction: [
          { amount: 7500, lower: 0, upper: 20499 },
          { amount: 7325, lower: 20500, upper: 20999 },
          { amount: 7150, lower: 21000, upper: 21499 },
          { amount: 6975, lower: 21500, upper: 21999 },
          { amount: 6800, lower: 22000, upper: 22499 },
          { amount: 6625, lower: 22500, upper: 22999 },
          { amount: 6450, lower: 23000, upper: 23499 },
          { amount: 6275, lower: 23500, upper: 23999 },
          { amount: 6100, lower: 24000, upper: 24499 },
          { amount: 5925, lower: 24500, upper: 24999 },
          { amount: 5750, lower: 25000, upper: 25499 },
          { amount: 5575, lower: 25500, upper: 25999 },
          { amount: 5400, lower: 26000, upper: 26499 },
          { amount: 5225, lower: 26500, upper: 26999 },
          { amount: 5050, lower: 27000, upper: 27499 },
          { amount: 4875, lower: 27500, upper: 27999 },
          { amount: 4700, lower: 28000, upper: 28499 },
          { amount: 4525, lower: 28500, upper: 28999 },
          { amount: 4350, lower: 29000, upper: 29499 },
          { amount: 4175, lower: 29500, upper: 29999 },
          { amount: 4000, lower: 30000, upper: null },
        ],
        personalExemption: [
          { amount: 3000, lower: 0, upper: null },
        ],
      },
    }
  ],
  [
    'AK',
    {
      single: {
        deductionByFederalTax: false,
        maximumFederalTaxDeductionForStateTax: undefined,
        taxBrackets: [
        ],
        standardDeduction: [
        ],
        personalExemption: [
        ],
      },
      married: {
        deductionByFederalTax: false,
        maximumFederalTaxDeductionForStateTax: undefined,
        taxBrackets: [
        ],
        standardDeduction: [
        ],
        personalExemption: [
        ],
      },
    }
  ],
  [
    'AZ',
    {
      single: {
        deductionByFederalTax: false,
        maximumFederalTaxDeductionForStateTax: undefined,
        taxBrackets: [
          { rate: 2.59, lower: 0, upper: 26500 },
          { rate: 3.34, lower: 26500, upper: 53000 },
          { rate: 4.17, lower: 53000, upper: 159000 },
          { rate: 4.50, lower: 159000, upper: null },
        ],
        standardDeduction: [
          { amount: 12200, lower: 0, upper: null },
        ],
        personalExemption: [
        ],
      },
      married: {
        deductionByFederalTax: false,
        maximumFederalTaxDeductionForStateTax: undefined,
        taxBrackets: [
          { rate: 2.59, lower: 0, upper: 53000 },
          { rate: 3.34, lower: 53000, upper: 106000 },
          { rate: 4.17, lower: 106000, upper: 318000 },
          { rate: 4.50, lower: 318000, upper: null },
        ],
        standardDeduction: [
          { amount: 24400, lower: 0, upper: null },
        ],
        personalExemption: [
        ],
      },
    }
  ],
  [
    'AR',
    {
      single: {
        deductionByFederalTax: false,
        maximumFederalTaxDeductionForStateTax: undefined,
        taxBrackets: [
          { rate: 0, lower: 0, upper: 4499 },
          { rate: 2, lower: 4500, upper: 8899 },
          { rate: 3, lower: 8900, upper: 13399 },
          { rate: 3.4, lower: 13400, upper: 22199 },
          { rate: 5, lower: 22200, upper: 37199 },
          { rate: 6, lower: 37200, upper: 79300 },
          { rate: 6.9, lower: 79300, upper: null },
        ],
        standardDeduction: [
          { amount: 2200, lower: 0, upper: null },
        ],
        personalExemption: [
        ],
      },
      married: {
        deductionByFederalTax: false,
        maximumFederalTaxDeductionForStateTax: undefined,
        taxBrackets: [
          { rate: 0, lower: 0, upper: 4499 },
          { rate: 2, lower: 4500, upper: 8899 },
          { rate: 3, lower: 8900, upper: 13399 },
          { rate: 3.4, lower: 13400, upper: 22199 },
          { rate: 5, lower: 22200, upper: 37199 },
          { rate: 6, lower: 37200, upper: 79300 },
          { rate: 6.9, lower: 79300, upper: null },
        ],
        standardDeduction: [
          { amount: 4400, lower: 0, upper: null },
        ],
        personalExemption: [
        ],
      },
    }
  ],
  [
    'CA',
    {
      single: {
        deductionByFederalTax: false,
        maximumFederalTaxDeductionForStateTax: undefined,
        taxBrackets: [
          { rate: 1.00, lower: 0, upper: 8809 },
          { rate: 2.00, lower: 8809, upper: 20883 },
          { rate: 4.00, lower: 20883, upper: 32960 },
          { rate: 6.00, lower: 32960, upper: 45753 },
          { rate: 8.00, lower: 45753, upper: 57824 },
          { rate: 9.30, lower: 57824, upper: 295373 },
          { rate: 10.30, lower: 295373, upper: 354445 },
          { rate: 11.30, lower: 354445, upper: 590742 },
          { rate: 12.30, lower: 590742, upper: 999999 },
          { rate: 13.30, lower: 999999, upper: null },
        ],
        standardDeduction: [
          { amount: 4537, lower: 0, upper: null },
        ],
        personalExemption: [
        ],
      },
      married: {
        deductionByFederalTax: false,
        maximumFederalTaxDeductionForStateTax: undefined,
        taxBrackets: [
          { rate: 1.00, lower: 0, upper: 17618 },
          { rate: 2.00, lower: 17618, upper: 41766 },
          { rate: 4.00, lower: 41766, upper: 65920 },
          { rate: 6.00, lower: 65920, upper: 91506 },
          { rate: 8.00, lower: 91506, upper: 115648 },
          { rate: 9.30, lower: 115648, upper: 590746 },
          { rate: 10.30, lower: 590746, upper: 708890 },
          { rate: 11.30, lower: 708890, upper: 1181484 },
          { rate: 12.30, lower: 1181484, upper: 1999999 },
          { rate: 13.30, lower: 1999999, upper: null },
        ],
        standardDeduction: [
          { amount: 9074, lower: 0, upper: null },
        ],
        personalExemption: [
        ],
      },
    }
  ],
  [
    'CO',
    {
      single: {
        deductionByFederalTax: false,
        maximumFederalTaxDeductionForStateTax: undefined,
        taxBrackets: [
          { rate: 4.63, lower: 0, upper: null },
        ],
        standardDeduction: [
          { amount: 12200, lower: 0, upper: null },
        ],
        personalExemption: [
        ],
      },
      married: {
        deductionByFederalTax: false,
        maximumFederalTaxDeductionForStateTax: undefined,
        taxBrackets: [
          { rate: 4.63, lower: 0, upper: null },
        ],
        standardDeduction: [
          { amount: 22400, lower: 0, upper: null },
        ],
        personalExemption: [
        ],
      },
    }
  ],
  [
    'CT',
    {
      single: {
        deductionByFederalTax: false,
        maximumFederalTaxDeductionForStateTax: undefined,
        taxBrackets: [
          { rate: 3.00, lower: 0, upper: 10000 },
          { rate: 5.00, lower: 10000, upper: 50000 },
          { rate: 5.50, lower: 50000, upper: 100000 },
          { rate: 6.00, lower: 100000, upper: 200000 },
          { rate: 6.50, lower: 200000, upper: 250000 },
          { rate: 6.90, lower: 250000, upper: 500000 },
          { rate: 6.99, lower: 500000, upper: null },
        ],
        standardDeduction: [
        ],
        personalExemption: [
          { amount: 15000, lower: 0, upper: 30000 },
          { amount: 14000, lower: 30000, upper: 31000 },
          { amount: 13000, lower: 31000, upper: 32000 },
          { amount: 12000, lower: 32000, upper: 33000 },
          { amount: 11000, lower: 33000, upper: 34000 },
          { amount: 10000, lower: 34000, upper: 35000 },
          { amount: 9000, lower: 35000, upper: 36000 },
          { amount: 8000, lower: 36000, upper: 37000 },
          { amount: 7000, lower: 37000, upper: 38000 },
          { amount: 6000, lower: 38000, upper: 39000 },
          { amount: 5000, lower: 39000, upper: 40000 },
          { amount: 4000, lower: 40000, upper: 41000 },
          { amount: 3000, lower: 41000, upper: 42000 },
          { amount: 2000, lower: 42000, upper: 43000 },
          { amount: 1000, lower: 43000, upper: 44000 },
          { amount: 0, lower: 44000, upper: null },
        ],
      },
      married: {
        deductionByFederalTax: false,
        maximumFederalTaxDeductionForStateTax: undefined,
        taxBrackets: [
          { rate: 3.00, lower: 0, upper: 20000 },
          { rate: 5.00, lower: 20000, upper: 100000 },
          { rate: 5.50, lower: 100000, upper: 200000 },
          { rate: 6.00, lower: 200000, upper: 400000 },
          { rate: 6.50, lower: 400000, upper: 500000 },
          { rate: 6.90, lower: 500000, upper: 1000000 },
          { rate: 6.99, lower: 1000000, upper: null },
        ],
        standardDeduction: [
        ],
        personalExemption: [
          { amount: 24000, lower: 0, upper: 48000 },
          { amount: 23000, lower: 48000, upper: 49000 },
          { amount: 22000, lower: 49000, upper: 50000 },
          { amount: 21000, lower: 50000, upper: 51000 },
          { amount: 20000, lower: 51000, upper: 52000 },
          { amount: 19000, lower: 52000, upper: 53000 },
          { amount: 18000, lower: 53000, upper: 54000 },
          { amount: 17000, lower: 54000, upper: 55000 },
          { amount: 16000, lower: 55000, upper: 56000 },
          { amount: 15000, lower: 56000, upper: 57000 },
          { amount: 14000, lower: 57000, upper: 58000 },
          { amount: 13000, lower: 58000, upper: 59000 },
          { amount: 12000, lower: 59000, upper: 60000 },
          { amount: 11000, lower: 60000, upper: 61000 },
          { amount: 10000, lower: 61000, upper: 62000 },
          { amount: 9000, lower: 62000, upper: 63000 },
          { amount: 8000, lower: 63000, upper: 64000 },
          { amount: 7000, lower: 64000, upper: 65000 },
          { amount: 6000, lower: 65000, upper: 66000 },
          { amount: 5000, lower: 66000, upper: 67000 },
          { amount: 4000, lower: 67000, upper: 68000 },
          { amount: 3000, lower: 68000, upper: 69000 },
          { amount: 2000, lower: 69000, upper: 70000 },
          { amount: 1000, lower: 70000, upper: 71000 },
          { amount: 0, lower: 71000, upper: null },
        ],
      },
    }
  ],
  [
    'DE',
    {
      single: {
        deductionByFederalTax: false,
        maximumFederalTaxDeductionForStateTax: undefined,
        taxBrackets: [
          { rate: 0.00, lower: 0, upper: 2000 },
          { rate: 2.20, lower: 2000, upper: 5000 },
          { rate: 3.90, lower: 5000, upper: 10000 },
          { rate: 4.80, lower: 10000, upper: 20000 },
          { rate: 5.20, lower: 20000, upper: 25000 },
          { rate: 5.55, lower: 25000, upper: 60000 },
          { rate: 6.60, lower: 60000, upper: null },
        ],
        standardDeduction: [
          { amount: 3250, lower: 0, upper: null },
        ],
        personalExemption: [
        ],
      },
      married: {
        deductionByFederalTax: false,
        maximumFederalTaxDeductionForStateTax: undefined,
        taxBrackets: [
          { rate: 0.00, lower: 0, upper: 2000 },
          { rate: 2.20, lower: 2000, upper: 5000 },
          { rate: 3.90, lower: 5000, upper: 10000 },
          { rate: 4.80, lower: 10000, upper: 20000 },
          { rate: 5.20, lower: 20000, upper: 25000 },
          { rate: 5.55, lower: 25000, upper: 60000 },
          { rate: 6.60, lower: 60000, upper: null },
        ],
        standardDeduction: [
          { amount: 6500, lower: 0, upper: null },
        ],
        personalExemption: [
        ],
      },
    }
  ],
  [
    'DC',
    {
      single: {
        deductionByFederalTax: false,
        maximumFederalTaxDeductionForStateTax: undefined,
        taxBrackets: [
          { rate: 4.00, lower: 0, upper: 10000 },
          { rate: 6.00, lower: 10000, upper: 40000 },
          { rate: 6.50, lower: 40000, upper: 60000 },
          { rate: 8.50, lower: 60000, upper: 350000 },
          { rate: 8.75, lower: 350000, upper: 1000000 },
          { rate: 8.95, lower: 1000000, upper: null },
        ],
        standardDeduction: [
          { amount: 12200, lower: 0, upper: null },
        ],
        personalExemption: [
        ],
      },
      married: {
        deductionByFederalTax: false,
        maximumFederalTaxDeductionForStateTax: undefined,
        taxBrackets: [
          { rate: 4.00, lower: 0, upper: 10000 },
          { rate: 6.00, lower: 10000, upper: 40000 },
          { rate: 6.50, lower: 40000, upper: 60000 },
          { rate: 8.50, lower: 60000, upper: 350000 },
          { rate: 8.75, lower: 350000, upper: 1000000 },
          { rate: 8.95, lower: 1000000, upper: null },
        ],
        standardDeduction: [
          { amount: 24400, lower: 0, upper: null },
        ],
        personalExemption: [
        ],
      },
    }
  ],
  [
    'FL',
    {
      single: {
        deductionByFederalTax: false,
        maximumFederalTaxDeductionForStateTax: undefined,
        taxBrackets: [
        ],
        standardDeduction: [
        ],
        personalExemption: [
        ],
      },
      married: {
        deductionByFederalTax: false,
        maximumFederalTaxDeductionForStateTax: undefined,
        taxBrackets: [
        ],
        standardDeduction: [
        ],
        personalExemption: [
        ],
      },
    }
  ],
  [
    'GA',
    {
      single: {
        deductionByFederalTax: false,
        maximumFederalTaxDeductionForStateTax: undefined,
        taxBrackets: [
          { rate: 1.00, lower: 0, upper: 750 },
          { rate: 2.00, lower: 750, upper: 2250 },
          { rate: 3.00, lower: 2250, upper: 3750 },
          { rate: 4.00, lower: 3750, upper: 5250 },
          { rate: 5.00, lower: 5250, upper: 7000 },
          { rate: 5.75, lower: 7000, upper: null },
        ],
        standardDeduction: [
          { amount: 4600, lower: 0, upper: null },
        ],
        personalExemption: [
          { amount: 2700, lower: 0, upper: null },
        ],
      },
      married: {
        deductionByFederalTax: false,
        maximumFederalTaxDeductionForStateTax: undefined,
        taxBrackets: [
          { rate: 1.00, lower: 0, upper: 1000 },
          { rate: 2.00, lower: 1000, upper: 3000 },
          { rate: 3.00, lower: 3000, upper: 5000 },
          { rate: 4.00, lower: 5000, upper: 7000 },
          { rate: 5.00, lower: 7000, upper: 10000 },
          { rate: 5.75, lower: 10000, upper: null },
        ],
        standardDeduction: [
          { amount: 6000, lower: 0, upper: null },
        ],
        personalExemption: [
          { amount: 7400, lower: 0, upper: null },
        ],
      },
    }
  ],
  [
    'HI',
    {
      single: {
        deductionByFederalTax: false,
        maximumFederalTaxDeductionForStateTax: undefined,
        taxBrackets: [
          { rate: 1.40, lower: 0, upper: 2400 },
          { rate: 3.20, lower: 2400, upper: 4800 },
          { rate: 5.50, lower: 4800, upper: 9600 },
          { rate: 6.40, lower: 9600, upper: 14400 },
          { rate: 6.80, lower: 14400, upper: 19200 },
          { rate: 7.20, lower: 19200, upper: 24000 },
          { rate: 7.60, lower: 24000, upper: 36000 },
          { rate: 7.90, lower: 36000, upper: 48000 },
          { rate: 8.25, lower: 48000, upper: 150000 },
          { rate: 9.00, lower: 150000, upper: 175000 },
          { rate: 10.00, lower: 175000, upper: 200000 },
          { rate: 11.00, lower: 200000, upper: null },
        ],
        standardDeduction: [
          { amount: 2200, lower: 0, upper: null },
        ],
        personalExemption: [
          { amount: 1144, lower: 0, upper: null },
        ],
      },
      married: {
        deductionByFederalTax: false,
        maximumFederalTaxDeductionForStateTax: undefined,
        taxBrackets: [
          { rate: 1.40, lower: 0, upper: 4800 },
          { rate: 3.20, lower: 4800, upper: 9600 },
          { rate: 5.50, lower: 9600, upper: 19200 },
          { rate: 6.40, lower: 19200, upper: 28800 },
          { rate: 6.80, lower: 28800, upper: 38400 },
          { rate: 7.20, lower: 38400, upper: 48000 },
          { rate: 7.60, lower: 48000, upper: 72000 },
          { rate: 7.90, lower: 72000, upper: 96000 },
          { rate: 8.25, lower: 96000, upper: 300000 },
          { rate: 9.00, lower: 300000, upper: 350000 },
          { rate: 10.00, lower: 350000, upper: 400000 },
          { rate: 11.00, lower: 400000, upper: null },
        ],
        standardDeduction: [
          { amount: 4400, lower: 0, upper: null },
        ],
        personalExemption: [
          { amount: 2288, lower: 0, upper: null },
        ],
      },
    }
  ],
  [
    'ID',
    {
      single: {
        deductionByFederalTax: false,
        maximumFederalTaxDeductionForStateTax: undefined,
        taxBrackets: [
          { rate: 1.125, lower: 0, upper: 1541 },
          { rate: 3.125, lower: 1541, upper: 3081 },
          { rate: 3.625, lower: 3081, upper: 4622 },
          { rate: 4.625, lower: 4622, upper: 6162 },
          { rate: 5.625, lower: 6162, upper: 7703 },
          { rate: 6.625, lower: 7703, upper: 11554 },
          { rate: 6.925, lower: 11554, upper: null },
        ],
        standardDeduction: [
          { amount: 12200, lower: 0, upper: null },
        ],
        personalExemption: [
        ],
      },
      married: {
        deductionByFederalTax: false,
        maximumFederalTaxDeductionForStateTax: undefined,
        taxBrackets: [
          { rate: 1.125, lower: 0, upper: 3082 },
          { rate: 3.125, lower: 3082, upper: 6162 },
          { rate: 3.625, lower: 6162, upper: 9244 },
          { rate: 4.625, lower: 9244, upper: 12324 },
          { rate: 5.625, lower: 12324, upper: 15406 },
          { rate: 6.625, lower: 15406, upper: 23108 },
          { rate: 6.925, lower: 23108, upper: null },
        ],
        standardDeduction: [
          { amount: 24400, lower: 0, upper: null },
        ],
        personalExemption: [
        ],
      },
    }
  ],
  [
    'IL',
    {
      single: {
        deductionByFederalTax: false,
        maximumFederalTaxDeductionForStateTax: undefined,
        taxBrackets: [
          { rate: 4.95, lower: 0, upper: null },
        ],
        standardDeduction: [
        ],
        personalExemption: [
          { amount: 2275, lower: 0, upper: null },
        ],
      },
      married: {
        deductionByFederalTax: false,
        maximumFederalTaxDeductionForStateTax: undefined,
        taxBrackets: [
          { rate: 4.95, lower: 0, upper: null },
        ],
        standardDeduction: [
        ],
        personalExemption: [
          { amount: 4550, lower: 0, upper: null },
        ],
      },
    }
  ],
  [
    'IN',
    {
      single: {
        deductionByFederalTax: false,
        maximumFederalTaxDeductionForStateTax: undefined,
        taxBrackets: [
          { rate: 3.23, lower: 0, upper: null },
        ],
        standardDeduction: [
        ],
        personalExemption: [
          { amount: 1000, lower: 0, upper: null },
        ],
      },
      married: {
        deductionByFederalTax: false,
        maximumFederalTaxDeductionForStateTax: undefined,
        taxBrackets: [
          { rate: 3.23, lower: 0, upper: null },
        ],
        standardDeduction: [
        ],
        personalExemption: [
          { amount: 2000, lower: 0, upper: null },
        ],
      },
    }
  ],
  [
    'IA',
    {
      single: {
        deductionByFederalTax: true,
        maximumFederalTaxDeductionForStateTax: null,
        taxBrackets: [
          { rate: 0.33, lower: 0, upper: 1638 },
          { rate: 0.67, lower: 1638, upper: 3276 },
          { rate: 2.25, lower: 3276, upper: 6552 },
          { rate: 4.14, lower: 6552, upper: 14742 },
          { rate: 5.63, lower: 14742, upper: 24570 },
          { rate: 5.96, lower: 24570, upper: 32760 },
          { rate: 6.25, lower: 32760, upper: 49140 },
          { rate: 7.44, lower: 49140, upper: 73710 },
          { rate: 8.53, lower: 73710, upper: null },
        ],
        standardDeduction: [
          { amount: 2080, lower: 0, upper: null },
        ],
        personalExemption: [
        ],
      },
      married: {
        deductionByFederalTax: true,
        maximumFederalTaxDeductionForStateTax: null,
        taxBrackets: [
          { rate: 0.33, lower: 0, upper: 1638 },
          { rate: 0.67, lower: 1638, upper: 3276 },
          { rate: 2.25, lower: 3276, upper: 6552 },
          { rate: 4.14, lower: 6552, upper: 14742 },
          { rate: 5.63, lower: 14742, upper: 24570 },
          { rate: 5.96, lower: 24570, upper: 32760 },
          { rate: 6.25, lower: 32760, upper: 49140 },
          { rate: 7.44, lower: 49140, upper: 73710 },
          { rate: 8.53, lower: 73710, upper: null },
        ],
        standardDeduction: [
          { amount: 5120, lower: 0, upper: null },
        ],
        personalExemption: [
        ],
      },
    }
  ],
  [
    'KS',
    {
      single: {
        deductionByFederalTax: false,
        maximumFederalTaxDeductionForStateTax: undefined,
        taxBrackets: [
          { rate: 3.10, lower: 0, upper: 15000 },
          { rate: 5.25, lower: 15000, upper: 30000 },
          { rate: 5.70, lower: 30000, upper: null },
        ],
        standardDeduction: [
          { amount: 3000, lower: 0, upper: null },
        ],
        personalExemption: [
          { amount: 2250, lower: 0, upper: null },
        ],
      },
      married: {
        deductionByFederalTax: false,
        maximumFederalTaxDeductionForStateTax: undefined,
        taxBrackets: [
          { rate: 3.10, lower: 0, upper: 30000 },
          { rate: 5.25, lower: 30000, upper: 60000 },
          { rate: 5.70, lower: 60000, upper: null },
        ],
        standardDeduction: [
          { amount: 7500, lower: 0, upper: null },
        ],
        personalExemption: [
          { amount: 4500, lower: 0, upper: null },
        ],
      },
    }
  ],
  [
    'KY',
    {
      single: {
        deductionByFederalTax: false,
        maximumFederalTaxDeductionForStateTax: undefined,
        taxBrackets: [
          { rate: 5, lower: 0, upper: null },
        ],
        standardDeduction: [
          { amount: 2590, lower: 0, upper: null },
        ],
        personalExemption: [
        ],
      },
      married: {
        deductionByFederalTax: false,
        maximumFederalTaxDeductionForStateTax: undefined,
        taxBrackets: [
          { rate: 5, lower: 0, upper: null },
        ],
        standardDeduction: [
          { amount: 5180, lower: 0, upper: null },
        ],
        personalExemption: [
        ],
      },
    }
  ],
  [
    'LA',
    {
      single: {
        deductionByFederalTax: true,
        maximumFederalTaxDeductionForStateTax: null,
        taxBrackets: [
          { rate: 2, lower: 0, upper: 12500 },
          { rate: 4, lower: 12500, upper: 50000 },
          { rate: 6, lower: 50000, upper: null },
        ],
        standardDeduction: [
        ],
        personalExemption: [
          { amount: 4500, lower: 0, upper: null },
        ],
      },
      married: {
        deductionByFederalTax: true,
        maximumFederalTaxDeductionForStateTax: null,
        taxBrackets: [
          { rate: 2, lower: 0, upper: 25000 },
          { rate: 4, lower: 25000, upper: 100000 },
          { rate: 6, lower: 100000, upper: null },
        ],
        standardDeduction: [
        ],
        personalExemption: [
          { amount: 9000, lower: 0, upper: null },
        ],
      },
    }
  ],
  [
    'ME',
    {
      single: {
        deductionByFederalTax: false,
        maximumFederalTaxDeductionForStateTax: undefined,
        taxBrackets: [
          { rate: 5.8, lower: 0, upper: 21850 },
          { rate: 6.75, lower: 21850, upper: 51700 },
          { rate: 7.15, lower: 51700, upper: null },
        ],
        standardDeduction: [
          { amount: 3900, lower: 0, upper: null },
        ],
        personalExemption: [
          { amount: 12200, lower: 0, upper: null },
        ],
      },
      married: {
        deductionByFederalTax: false,
        maximumFederalTaxDeductionForStateTax: undefined,
        taxBrackets: [
          { rate: 5.8, lower: 0, upper: 43700 },
          { rate: 6.75, lower: 43700, upper: 103400 },
          { rate: 7.15, lower: 103400, upper: null },
        ],
        standardDeduction: [
          { amount: 8400, lower: 0, upper: null },
        ],
        personalExemption: [
          { amount: 24400, lower: 0, upper: null },
        ],
      },
    }
  ],
  [
    'MD',
    {
      single: {
        deductionByFederalTax: false,
        maximumFederalTaxDeductionForStateTax: undefined,
        taxBrackets: [
          { rate: 2.00, lower: 0, upper: 1000 },
          { rate: 3.00, lower: 1000, upper: 2000 },
          { rate: 4.00, lower: 2000, upper: 3000 },
          { rate: 4.75, lower: 3000, upper: 100000 },
          { rate: 5.00, lower: 100000, upper: 125000 },
          { rate: 5.25, lower: 125000, upper: 150000 },
          { rate: 5.50, lower: 150000, upper: 250000 },
          { rate: 5.75, lower: 250000, upper: null },
        ],
        standardDeduction: [
        ],
        personalExemption: [
          { amount: 3200, lower: 0, upper: null },
        ],
      },
      married: {
        deductionByFederalTax: false,
        maximumFederalTaxDeductionForStateTax: undefined,
        taxBrackets: [
          { rate: 2.00, lower: 0, upper: 1000 },
          { rate: 3.00, lower: 1000, upper: 2000 },
          { rate: 4.00, lower: 2000, upper: 3000 },
          { rate: 4.75, lower: 3000, upper: 150000 },
          { rate: 5.00, lower: 150000, upper: 175000 },
          { rate: 5.25, lower: 175000, upper: 225000 },
          { rate: 5.50, lower: 225000, upper: 300000 },
          { rate: 5.75, lower: 300000, upper: null },
        ],
        standardDeduction: [
        ],
        personalExemption: [
          { amount: 3200, lower: 0, upper: null },
        ],
      },
    }
  ],
  [
    'MA',
    {
      single: {
        deductionByFederalTax: false,
        maximumFederalTaxDeductionForStateTax: undefined,
        taxBrackets: [
          { rate: 5.05, lower: 0, upper: null },
        ],
        standardDeduction: [
        ],
        personalExemption: [
          { amount: 4400, lower: 0, upper: null },
        ],
      },
      married: {
        deductionByFederalTax: false,
        maximumFederalTaxDeductionForStateTax: undefined,
        taxBrackets: [
          { rate: 5.05, lower: 0, upper: null },
        ],
        standardDeduction: [
        ],
        personalExemption: [
          { amount: 8800, lower: 0, upper: null },
        ],
      },
    }
  ],
  [
    'MI',
    {
      single: {
        deductionByFederalTax: false,
        maximumFederalTaxDeductionForStateTax: undefined,
        taxBrackets: [
          { rate: 4.25, lower: 0, upper: null },
        ],
        standardDeduction: [
        ],
        personalExemption: [
          { amount: 4400, lower: 0, upper: null },
        ],
      },
      married: {
        deductionByFederalTax: false,
        maximumFederalTaxDeductionForStateTax: undefined,
        taxBrackets: [
          { rate: 4.25, lower: 0, upper: null },
        ],
        standardDeduction: [
        ],
        personalExemption: [
          { amount: 8800, lower: 0, upper: null },
        ],
      },
    }
  ],
  [
    'MN',
    {
      single: {
        deductionByFederalTax: false,
        maximumFederalTaxDeductionForStateTax: undefined,
        taxBrackets: [
          { rate: 5.35, lower: 0, upper: 26520 },
          { rate: 6.80, lower: 26520, upper: 87110 },
          { rate: 7.85, lower: 87110, upper: 161720 },
          { rate: 9.85, lower: 161720, upper: null },
        ],
        standardDeduction: [
          { amount: 12200, lower: 0, upper: null },
        ],
        personalExemption: [
        ],
      },
      married: {
        deductionByFederalTax: false,
        maximumFederalTaxDeductionForStateTax: undefined,
        taxBrackets: [
          { rate: 5.35, lower: 0, upper: 38770 },
          { rate: 6.80, lower: 38770, upper: 154020 },
          { rate: 7.85, lower: 154020, upper: 269010 },
          { rate: 9.85, lower: 269010, upper: null },
        ],
        standardDeduction: [
          { amount: 24400, lower: 0, upper: null },
        ],
        personalExemption: [
        ],
      },
    }
  ],
  [
    'MS',
    {
      single: {
        deductionByFederalTax: false,
        maximumFederalTaxDeductionForStateTax: undefined,
        taxBrackets: [
          { rate: 0, lower: 0, upper: 1000 },
          { rate: 3, lower: 1000, upper: 5000 },
          { rate: 4, lower: 5000, upper: 10000 },
          { rate: 5, lower: 10000, upper: null },
        ],
        standardDeduction: [
          { amount: 2300, lower: 0, upper: null },
        ],
        personalExemption: [
          { amount: 6000, lower: 0, upper: null },
        ],
      },
      married: {
        deductionByFederalTax: false,
        maximumFederalTaxDeductionForStateTax: undefined,
        taxBrackets: [
          { rate: 0, lower: 0, upper: 1000 },
          { rate: 3, lower: 1000, upper: 5000 },
          { rate: 4, lower: 5000, upper: 10000 },
          { rate: 5, lower: 10000, upper: null },
        ],
        standardDeduction: [
          { amount: 4600, lower: 0, upper: null },
        ],
        personalExemption: [
          { amount: 12000, lower: 0, upper: null },
        ],
      },
    }
  ],
  [
    'MO',
    {
      single: {
        deductionByFederalTax: true,
        maximumFederalTaxDeductionForStateTax: null,
        taxBrackets: [
          { rate: 0.0, lower: 0, upper: 104 },
          { rate: 1.5, lower: 104, upper: 1053 },
          { rate: 2.0, lower: 1053, upper: 2106 },
          { rate: 2.5, lower: 2106, upper: 3159 },
          { rate: 3.0, lower: 3159, upper: 4212 },
          { rate: 3.5, lower: 4212, upper: 5265 },
          { rate: 4.0, lower: 5265, upper: 6318 },
          { rate: 4.5, lower: 6318, upper: 7371 },
          { rate: 5.0, lower: 7371, upper: 8424 },
          { rate: 5.4, lower: 8424, upper: null },
        ],
        standardDeduction: [
          { amount: 12200, lower: 0, upper: null },
        ],
        personalExemption: [
        ],
      },
      married: {
        deductionByFederalTax: true,
        maximumFederalTaxDeductionForStateTax: null,
        taxBrackets: [
          { rate: 0.0, lower: 0, upper: 104 },
          { rate: 1.5, lower: 104, upper: 1053 },
          { rate: 2.0, lower: 1053, upper: 2106 },
          { rate: 2.5, lower: 2106, upper: 3159 },
          { rate: 3.0, lower: 3159, upper: 4212 },
          { rate: 3.5, lower: 4212, upper: 5265 },
          { rate: 4.0, lower: 5265, upper: 6318 },
          { rate: 4.5, lower: 6318, upper: 7371 },
          { rate: 5.0, lower: 7371, upper: 8424 },
          { rate: 5.4, lower: 8424, upper: null },
        ],
        standardDeduction: [
          { amount: 24400, lower: 0, upper: null },
        ],
        personalExemption: [
        ],
      },
    }
  ],
  [
    'NE',
    {
      single: {
        deductionByFederalTax: false,
        maximumFederalTaxDeductionForStateTax: null,
        taxBrackets: [
          { rate: 2.46, lower: 0, upper: 3230 },
          { rate: 3.51, lower: 3230, upper: 19330 },
          { rate: 5.01, lower: 19330, upper: 31160 },
          { rate: 6.84, lower: 31160, upper: null },
        ],
        standardDeduction: [
          { amount: 6900, lower: 0, upper: null },
        ],
        personalExemption: [
        ],
      },
      married: {
        deductionByFederalTax: false,
        maximumFederalTaxDeductionForStateTax: null,
        taxBrackets: [
          { rate: 2.46, lower: 0, upper: 6440 },
          { rate: 3.51, lower: 6440, upper: 38680 },
          { rate: 5.01, lower: 38680, upper: 62320 },
          { rate: 6.84, lower: 62320, upper: null },
        ],
        standardDeduction: [
          { amount: 13800, lower: 0, upper: null },
        ],
        personalExemption: [
        ],
      },
    }
  ],
  [
    'NV',
    {
      single: {
        deductionByFederalTax: false,
        maximumFederalTaxDeductionForStateTax: null,
        taxBrackets: [
        ],
        standardDeduction: [
        ],
        personalExemption: [
        ],
      },
      married: {
        deductionByFederalTax: false,
        maximumFederalTaxDeductionForStateTax: null,
        taxBrackets: [
        ],
        standardDeduction: [
        ],
        personalExemption: [
        ],
      },
    }
  ],
  [
    'NH',
    {
      single: {
        deductionByFederalTax: false,
        maximumFederalTaxDeductionForStateTax: null,
        taxBrackets: [
        ],
        standardDeduction: [
        ],
        personalExemption: [
        ],
      },
      married: {
        deductionByFederalTax: false,
        maximumFederalTaxDeductionForStateTax: null,
        taxBrackets: [
        ],
        standardDeduction: [
        ],
        personalExemption: [
        ],
      },
    }
  ],
  [
    'NJ',
    {
      single: {
        deductionByFederalTax: false,
        maximumFederalTaxDeductionForStateTax: null,
        taxBrackets: [
          { rate: 1.400, lower: 0, upper: 20000 },
          { rate: 1.750, lower: 20000, upper: 35000 },
          { rate: 3.500, lower: 35000, upper: 40000 },
          { rate: 5.525, lower: 40000, upper: 75000 },
          { rate: 6.370, lower: 75000, upper: 500000 },
          { rate: 8.970, lower: 500000, upper: 5000000 },
          { rate: 10.750, lower: 5000000, upper: null },
        ],
        standardDeduction: [
        ],
        personalExemption: [
          { amount: 1000, lower: 0, upper: null },
        ],
      },
      married: {
        deductionByFederalTax: false,
        maximumFederalTaxDeductionForStateTax: null,
        taxBrackets: [
          { rate: 1.400, lower: 0, upper: 20000 },
          { rate: 1.750, lower: 20000, upper: 50000 },
          { rate: 2.450, lower: 50000, upper: 70000 },
          { rate: 3.500, lower: 70000, upper: 80000 },
          { rate: 5.525, lower: 80000, upper: 150000 },
          { rate: 6.370, lower: 150000, upper: 500000 },
          { rate: 8.970, lower: 500000, upper: 5000000 },
          { rate: 10.750, lower: 5000000, upper: null },
        ],
        standardDeduction: [
        ],
        personalExemption: [
          { amount: 2000, lower: 0, upper: null },
        ],
      },
    }
  ],
  [
    'NM',
    {
      single: {
        deductionByFederalTax: false,
        maximumFederalTaxDeductionForStateTax: null,
        taxBrackets: [
          { rate: 1.70, lower: 0, upper: 5500 },
          { rate: 3.20, lower: 5500, upper: 11000 },
          { rate: 4.70, lower: 11000, upper: 16000 },
          { rate: 4.90, lower: 16000, upper: null },
        ],
        standardDeduction: [
          { amount: 12200, lower: 0, upper: null },
        ],
        personalExemption: [
        ],
      },
      married: {
        deductionByFederalTax: false,
        maximumFederalTaxDeductionForStateTax: null,
        taxBrackets: [
          { rate: 1.70, lower: 0, upper: 8000 },
          { rate: 3.20, lower: 8000, upper: 16000 },
          { rate: 4.70, lower: 16000, upper: 24000 },
          { rate: 4.90, lower: 24000, upper: null },
        ],
        standardDeduction: [
          { amount: 24400, lower: 0, upper: null },
        ],
        personalExemption: [
        ],
      },
    }
  ],
  [
    'NY',
    {
      single: {
        deductionByFederalTax: false,
        maximumFederalTaxDeductionForStateTax: null,
        taxBrackets: [
          { rate: 4.00, lower: 0, upper: 8500 },
          { rate: 4.50, lower: 8500, upper: 11700 },
          { rate: 5.25, lower: 11700, upper: 13900 },
          { rate: 5.90, lower: 13900, upper: 21400 },
          { rate: 6.21, lower: 21400, upper: 80650 },
          { rate: 6.49, lower: 80650, upper: 215400 },
          { rate: 6.85, lower: 215400, upper: 1077550 },
          { rate: 8.82, lower: 1077550, upper: null },
        ],
        standardDeduction: [
          { amount: 8000, lower: 0, upper: null },
        ],
        personalExemption: [
        ],
      },
      married: {
        deductionByFederalTax: false,
        maximumFederalTaxDeductionForStateTax: null,
        taxBrackets: [
          { rate: 4.00, lower: 0, upper: 17150 },
          { rate: 4.50, lower: 17150, upper: 23600 },
          { rate: 5.25, lower: 23600, upper: 27900 },
          { rate: 5.90, lower: 27900, upper: 43000 },
          { rate: 6.21, lower: 43000, upper: 161550 },
          { rate: 6.49, lower: 161550, upper: 323200 },
          { rate: 6.85, lower: 323200, upper: 2155350 },
          { rate: 8.82, lower: 2155350, upper: null },
        ],
        standardDeduction: [
          { amount: 16050, lower: 0, upper: null },
        ],
        personalExemption: [
        ],
      },
    }
  ],
  [
    'NC',
    {
      single: {
        deductionByFederalTax: false,
        maximumFederalTaxDeductionForStateTax: null,
        taxBrackets: [
          { rate: 5.25, lower: 0, upper: null },
        ],
        standardDeduction: [
          { amount: 10000, lower: 0, upper: null },
        ],
        personalExemption: [
        ],
      },
      married: {
        deductionByFederalTax: false,
        maximumFederalTaxDeductionForStateTax: null,
        taxBrackets: [
          { rate: 5.25, lower: 0, upper: null },
        ],
        standardDeduction: [
          { amount: 20000, lower: 0, upper: null },
        ],
        personalExemption: [
        ],
      },
    }
  ],
  [
    'ND',
    {
      single: {
        deductionByFederalTax: false,
        maximumFederalTaxDeductionForStateTax: null,
        taxBrackets: [
          { rate: 1.10, lower: 0, upper: 39450 },
          { rate: 2.04, lower: 39450, upper: 95500 },
          { rate: 2.27, lower: 95500, upper: 199250 },
          { rate: 2.64, lower: 199250, upper: 433200 },
          { rate: 2.9, lower: 433200, upper: null },
        ],
        standardDeduction: [
          { amount: 12200, lower: 0, upper: null },
        ],
        personalExemption: [
        ],
      },
      married: {
        deductionByFederalTax: false,
        maximumFederalTaxDeductionForStateTax: null,
        taxBrackets: [
          { rate: 1.10, lower: 0, upper: 65900 },
          { rate: 2.04, lower: 65900, upper: 159200 },
          { rate: 2.27, lower: 159200, upper: 242550 },
          { rate: 2.64, lower: 242550, upper: 433200 },
          { rate: 2.90, lower: 433200, upper: null },
        ],
        standardDeduction: [
          { amount: 24400, lower: 0, upper: null },
        ],
        personalExemption: [
        ],
      },
    }
  ],
  [
    'OH',
    {
      single: {
        deductionByFederalTax: false,
        maximumFederalTaxDeductionForStateTax: null,
        taxBrackets: [
          { rate: 0.000, lower: 0, upper: 21750 },
          { rate: 2.850, lower: 21750, upper: 43450 },
          { rate: 3.326, lower: 43450, upper: 86900 },
          { rate: 3.802, lower: 86900, upper: 108700 },
          { rate: 4.413, lower: 108700, upper: 217400 },
          { rate: 4.797, lower: 217400, upper: null },
        ],
        standardDeduction: [
        ],
        personalExemption: [
          { amount: 2350, lower: 0, upper: 40000 },
          { amount: 2100, lower: 40000, upper: 80000 },
          { amount: 1850, lower: 80000, upper: null },
        ],
      },
      married: {
        deductionByFederalTax: false,
        maximumFederalTaxDeductionForStateTax: null,
        taxBrackets: [
          { rate: 0.000, lower: 0, upper: 21750 },
          { rate: 2.850, lower: 21750, upper: 43450 },
          { rate: 3.326, lower: 43450, upper: 86900 },
          { rate: 3.802, lower: 86900, upper: 108700 },
          { rate: 4.413, lower: 108700, upper: 217400 },
          { rate: 4.797, lower: 217400, upper: null },
        ],
        standardDeduction: [
          { amount: 4700, lower: 0, upper: 40000 },
          { amount: 4200, lower: 40000, upper: 80000 },
          { amount: 3700, lower: 80000, upper: null },
        ],
        personalExemption: [
        ],
      },
    }
  ],
  [
    'OK',
    {
      single: {
        deductionByFederalTax: false,
        maximumFederalTaxDeductionForStateTax: null,
        taxBrackets: [
          { rate: 0.5, lower: 0, upper: 1000 },
          { rate: 1.0, lower: 1000, upper: 2500 },
          { rate: 2.0, lower: 2500, upper: 3750 },
          { rate: 3.0, lower: 3750, upper: 4900 },
          { rate: 4.0, lower: 4900, upper: 7200 },
          { rate: 5.0, lower: 7200, upper: null },
        ],
        standardDeduction: [
          { amount: 6350, lower: 0, upper: null },
        ],
        personalExemption: [
          { amount: 1000, lower: 0, upper: null },
        ],
      },
      married: {
        deductionByFederalTax: false,
        maximumFederalTaxDeductionForStateTax: null,
        taxBrackets: [
          { rate: 0.5, lower: 0, upper: 2000 },
          { rate: 1.0, lower: 2000, upper: 5000 },
          { rate: 2.0, lower: 5000, upper: 7500 },
          { rate: 3.0, lower: 7500, upper: 9800 },
          { rate: 4.0, lower: 9800, upper: 12200 },
          { rate: 5.0, lower: 12200, upper: null },
        ],
        standardDeduction: [
          { amount: 12700, lower: 0, upper: null },
        ],
        personalExemption: [
          { amount: 2000, lower: 0, upper: null },
        ],
      },
    }
  ],
  [
    'OR',
    {
      single: {
        deductionByFederalTax: true,
        maximumFederalTaxDeductionForStateTax: 6650,
        taxBrackets: [
          { rate: 5.00, lower: 0, upper: 3550 },
          { rate: 7.00, lower: 3550, upper: 8900 },
          { rate: 9.00, lower: 8900, upper: 125000 },
          { rate: 9.90, lower: 125000, upper: null },
        ],
        standardDeduction: [
          { amount: 2270, lower: 0, upper: null },
        ],
        personalExemption: [
        ],
      },
      married: {
        deductionByFederalTax: true,
        maximumFederalTaxDeductionForStateTax: 6650,
        taxBrackets: [
          { rate: 5.00, lower: 0, upper: 7100 },
          { rate: 7.00, lower: 7100, upper: 17800 },
          { rate: 9.00, lower: 17800, upper: 250000 },
          { rate: 9.90, lower: 250000, upper: null },
        ],
        standardDeduction: [
          { amount: 4545, lower: 0, upper: null },
        ],
        personalExemption: [
        ],
      },
    }
  ],
  [
    'PA',
    {
      single: {
        deductionByFederalTax: false,
        maximumFederalTaxDeductionForStateTax: undefined,
        taxBrackets: [
          { rate: 3.07, lower: 0, upper: null },
        ],
        standardDeduction: [
        ],
        personalExemption: [
        ],
      },
      married: {
        deductionByFederalTax: false,
        maximumFederalTaxDeductionForStateTax: undefined,
        taxBrackets: [
          { rate: 3.07, lower: 0, upper: null },
        ],
        standardDeduction: [
        ],
        personalExemption: [
        ],
      },
    }
  ],
  [
    'RI',
    {
      single: {
        deductionByFederalTax: false,
        maximumFederalTaxDeductionForStateTax: undefined,
        taxBrackets: [
          { rate: 3.75, lower: 0, upper: 64050 },
          { rate: 4.75, lower: 64050, upper: 145600 },
          { rate: 5.99, lower: 145600, upper: null },
        ],
        standardDeduction: [
          { amount: 4100, lower: 0, upper: null },
        ],
        personalExemption: [
          { amount: 8750, lower: 0, upper: null },
        ],
      },
      married: {
        deductionByFederalTax: false,
        maximumFederalTaxDeductionForStateTax: undefined,
        taxBrackets: [
          { rate: 3.75, lower: 0, upper: 64050 },
          { rate: 4.75, lower: 64050, upper: 145600 },
          { rate: 5.99, lower: 145600, upper: null },
        ],
        standardDeduction: [
          { amount: 8200, lower: 0, upper: null },
        ],
        personalExemption: [
          { amount: 17500, lower: 0, upper: null },
        ],
      },
    }
  ],
  [
    'SC',
    {
      single: {
        deductionByFederalTax: false,
        maximumFederalTaxDeductionForStateTax: undefined,
        taxBrackets: [
          { rate: 0, lower: 0, upper: 3030 },
          { rate: 3, lower: 3030, upper: 6060 },
          { rate: 4, lower: 6060, upper: 9090 },
          { rate: 5, lower: 9090, upper: 12120 },
          { rate: 6, lower: 12120, upper: 15160 },
          { rate: 7, lower: 15160, upper: null },
        ],
        standardDeduction: [
          { amount: 12200, lower: 0, upper: null },
        ],
        personalExemption: [
        ],
      },
      married: {
        deductionByFederalTax: false,
        maximumFederalTaxDeductionForStateTax: undefined,
        taxBrackets: [
          { rate: 0, lower: 0, upper: 3030 },
          { rate: 3, lower: 3030, upper: 6060 },
          { rate: 4, lower: 6060, upper: 9090 },
          { rate: 5, lower: 9090, upper: 12120 },
          { rate: 6, lower: 12120, upper: 15160 },
          { rate: 7, lower: 15160, upper: null },
        ],
        standardDeduction: [
          { amount: 24400, lower: 0, upper: null },
        ],
        personalExemption: [
        ],
      },
    }
  ],
  [
    'SD',
    {
      single: {
        deductionByFederalTax: false,
        maximumFederalTaxDeductionForStateTax: undefined,
        taxBrackets: [
        ],
        standardDeduction: [
        ],
        personalExemption: [
        ],
      },
      married: {
        deductionByFederalTax: false,
        maximumFederalTaxDeductionForStateTax: undefined,
        taxBrackets: [
        ],
        standardDeduction: [
        ],
        personalExemption: [
        ],
      },
    }
  ],
  [
    'TN',
    {
      single: {
        deductionByFederalTax: false,
        maximumFederalTaxDeductionForStateTax: undefined,
        taxBrackets: [
        ],
        standardDeduction: [
        ],
        personalExemption: [
        ],
      },
      married: {
        deductionByFederalTax: false,
        maximumFederalTaxDeductionForStateTax: undefined,
        taxBrackets: [
        ],
        standardDeduction: [
        ],
        personalExemption: [
        ],
      },
    }
  ],
  [
    'TX',
    {
      single: {
        deductionByFederalTax: false,
        maximumFederalTaxDeductionForStateTax: undefined,
        taxBrackets: [
        ],
        standardDeduction: [
        ],
        personalExemption: [
        ],
      },
      married: {
        deductionByFederalTax: false,
        maximumFederalTaxDeductionForStateTax: undefined,
        taxBrackets: [
        ],
        standardDeduction: [
        ],
        personalExemption: [
        ],
      },
    }
  ],
  [
    'UT',
    {
      single: {
        deductionByFederalTax: false,
        maximumFederalTaxDeductionForStateTax: undefined,
        taxBrackets: [
          { rate: 4.95, lower: 0, upper: null },
        ],
        standardDeduction: [
        ],
        personalExemption: [
        ],
      },
      married: {
        deductionByFederalTax: false,
        maximumFederalTaxDeductionForStateTax: undefined,
        taxBrackets: [
          { rate: 4.95, lower: 0, upper: null },
        ],
        standardDeduction: [
        ],
        personalExemption: [
        ],
      },
    }
  ],
  [
    'VT',
    {
      single: {
        deductionByFederalTax: false,
        maximumFederalTaxDeductionForStateTax: undefined,
        taxBrackets: [
          { rate: 3.35, lower: 0, upper: 39600 },
          { rate: 6.60, lower: 39600, upper: 96000 },
          { rate: 7.60, lower: 96000, upper: 200200 },
          { rate: 8.75, lower: 200200, upper: null },
        ],
        standardDeduction: [
          { amount: 6150, lower: 0, upper: null },
        ],
        personalExemption: [
          { amount: 4150, lower: 0, upper: null },
        ],
      },
      married: {
        deductionByFederalTax: false,
        maximumFederalTaxDeductionForStateTax: undefined,
        taxBrackets: [
          { rate: 3.35, lower: 0, upper: 66150 },
          { rate: 6.60, lower: 66150, upper: 159950 },
          { rate: 7.60, lower: 159950, upper: 243750 },
          { rate: 8.75, lower: 243750, upper: null },
        ],
        standardDeduction: [
          { amount: 12300, lower: 0, upper: null },
        ],
        personalExemption: [
          { amount: 8300, lower: 0, upper: null },
        ],
      },
    }
  ],
  [
    'VA',
    {
      single: {
        deductionByFederalTax: false,
        maximumFederalTaxDeductionForStateTax: undefined,
        taxBrackets: [
          { rate: 2.00, lower: 0, upper: 3000 },
          { rate: 3.00, lower: 3000, upper: 5000 },
          { rate: 5.00, lower: 5000, upper: 17000 },
          { rate: 5.75, lower: 17000, upper: null },
        ],
        standardDeduction: [
          { amount: 4500, lower: 0, upper: null },
        ],
        personalExemption: [
          { amount: 930, lower: 0, upper: null },
        ],
      },
      married: {
        deductionByFederalTax: false,
        maximumFederalTaxDeductionForStateTax: undefined,
        taxBrackets: [
          { rate: 2.00, lower: 0, upper: 3000 },
          { rate: 3.00, lower: 3000, upper: 5000 },
          { rate: 5.00, lower: 5000, upper: 17000 },
          { rate: 5.75, lower: 17000, upper: null },
        ],
        standardDeduction: [
          { amount: 9000, lower: 0, upper: null },
        ],
        personalExemption: [
          { amount: 1860, lower: 0, upper: null },
        ],
      },
    }
  ],
  [
    'WA',
    {
      single: {
        deductionByFederalTax: false,
        maximumFederalTaxDeductionForStateTax: undefined,
        taxBrackets: [
        ],
        standardDeduction: [
        ],
        personalExemption: [
        ],
      },
      married: {
        deductionByFederalTax: false,
        maximumFederalTaxDeductionForStateTax: undefined,
        taxBrackets: [
        ],
        standardDeduction: [
        ],
        personalExemption: [
        ],
      },
    }
  ],
  [
    'WV',
    {
      single: {
        deductionByFederalTax: false,
        maximumFederalTaxDeductionForStateTax: undefined,
        taxBrackets: [
          { rate: 3.00, lower: 0, upper: 10000 },
          { rate: 4.00, lower: 10000, upper: 25000 },
          { rate: 4.50, lower: 25000, upper: 40000 },
          { rate: 6.00, lower: 40000, upper: 60000 },
          { rate: 6.50, lower: 60000, upper: null },
        ],
        standardDeduction: [
        ],
        personalExemption: [
          { amount: 2000, lower: 0, upper: null },
        ],
      },
      married: {
        deductionByFederalTax: false,
        maximumFederalTaxDeductionForStateTax: undefined,
        taxBrackets: [
          { rate: 3.00, lower: 0, upper: 10000 },
          { rate: 4.00, lower: 10000, upper: 25000 },
          { rate: 4.50, lower: 25000, upper: 40000 },
          { rate: 6.00, lower: 40000, upper: 60000 },
          { rate: 6.50, lower: 60000, upper: null },
        ],
        standardDeduction: [
        ],
        personalExemption: [
          { amount: 4000, lower: 0, upper: null },
        ],
      },
    }
  ],
  [
    'WI',
    {
      single: {
        deductionByFederalTax: false,
        maximumFederalTaxDeductionForStateTax: undefined,
        taxBrackets: [
          { rate: 3.86, lower: 0, upper: 11760 },
          { rate: 5.04, lower: 11760, upper: 23520 },
          { rate: 6.27, lower: 23520, upper: 258950 },
          { rate: 7.65, lower: 258950, upper: null },
        ],
        standardDeduction: [
          { amount: 10580, lower: 0, upper: null },
        ],
        personalExemption: [
          { amount: 700, lower: 0, upper: null },
        ],
      },
      married: {
        deductionByFederalTax: false,
        maximumFederalTaxDeductionForStateTax: undefined,
        taxBrackets: [
          { rate: 3.86, lower: 0, upper: 15680 },
          { rate: 5.04, lower: 15680, upper: 31360 },
          { rate: 6.27, lower: 31360, upper: 345270 },
          { rate: 7.65, lower: 345270, upper: null },
        ],
        standardDeduction: [
          { amount: 19580, lower: 0, upper: null },
        ],
        personalExemption: [
          { amount: 1400, lower: 0, upper: null },
        ],
      },
    }
  ],
  [
    'WY',
    {
      single: {
        deductionByFederalTax: false,
        maximumFederalTaxDeductionForStateTax: undefined,
        taxBrackets: [
        ],
        standardDeduction: [
        ],
        personalExemption: [
        ],
      },
      married: {
        deductionByFederalTax: false,
        maximumFederalTaxDeductionForStateTax: undefined,
        taxBrackets: [
        ],
        standardDeduction: [
        ],
        personalExemption: [
        ],
      },
    }
  ],
]);