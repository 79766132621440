import React, { CSSProperties, Fragment } from 'react';

import PopoverComponent from './PopoverComponent';
import { AppFont } from '../common';

/** Represents a row in a table. */
export interface SectionTableItem {
  /** Title for the row. */
  title: string,
  /**
   * Descriptions for title. If not empty, an icon is displayed next to the title, which, upon
   * focused (hovered over or touched), triggers a popover component that displays these
   * descriptions.
   */
  titleDescriptions: Array<string>,
  /** Value for the row. */
  value?: string,
  /**
   * Descriptions for value. If not empty, an icon is displayed next to the value, which, upon
   * focused (hovered over or touched), triggers a popover component that displays these
   * descriptions.
   */
  valueDescriptions: Array<string>,
  /** Every row can have subitems, which can be used to further demonstrate the row. */
  subitems: Array<SectionTableItem>,
};

/** Represents a table. */
export interface SectionTable {
  /** Title for the entire table. */
  title: string,
  /** A description placed below @c title. */
  description?: string,
  /** An array of items that make up the table. Each item is a row. */
  items: Array<SectionTableItem>,
};

interface TableComponentProps {
  style?: CSSProperties,
  table: SectionTable,
}

function TableComponent(props: TableComponentProps) {
  const style: CSSProperties = props.style ?? {};
  const { items, title, description } = props.table;
  const columns: number = items.length === 0 ? 1 : 1 + totalColumnsOfSectionTable(props.table);

  return (
    <table style={{ ...style, ...sectionTableContainerStyle }}>
      <tbody style={sectionTableBodyStyle}>
        <tr>
          <td style={sectionHeaderContainerStyle} colSpan={columns - 1}>
            <div style={sectionHeaderTitleStyle}>{title}</div>
            {description === undefined ? null : <div style={sectionHeaderSubtitleStyle}>
              {description}
            </div>}
          </td>
        </tr>
        {items.map((item, index) => renderTableItem(item, 0, index + 1, columns))}
      </tbody>
    </table>
  );
}

/**
 * Returns the number of columns of @c sectionTable.
 *
 * @param sectionTable Table whose total number of columns is to be counted.
 */
function totalColumnsOfSectionTable(sectionTable: SectionTable): number {
  let maximum: number = 1;

  for (let i = 0; i < sectionTable.items.length; ++i) {
    maximum = Math.max(maximum, totalColumnsOfTableItem(sectionTable.items[i], 0));
  }

  return maximum;
}

/**
 * Returns total number of columns of @c item.
 *
 * @param item Item whose total number of columns is to be counted.
 * @param leadingEmptyCells Number of leading empty cells rendered before this item.
 */
function totalColumnsOfTableItem(item: SectionTableItem, leadingEmptyCells: number): number {
  let maximum: number = (item.value === undefined ? 1 : 2) + leadingEmptyCells;

  for (let i = 0; i < item.subitems.length; ++i) {
    maximum = Math.max(maximum, totalColumnsOfTableItem(item.subitems[i], leadingEmptyCells + 1));
  }

  return maximum;
}

/**
 * Renders a single table item, including all of its subitems.
 *
 * @param item Current table item to be rendered in the table.
 * @param leadingEmptyCells Number of leading empty cells for current table item.
 * @param row Row number for current table item.
 * @param totalColumns Total columns for this table.
 */
function renderTableItem(
  item: SectionTableItem, leadingEmptyCells: number, row: number, totalColumns: number) {
  return (
    <Fragment key={item.title + row}>
      <tr style={sectionRowStyle(row)}>
        {emptyCells(leadingEmptyCells)}
        <td colSpan={item.value === undefined ? totalColumns - leadingEmptyCells : 1}>
          <div style={sectionCellContainerStyle}>
            <div style={sectionCellTitleStyle}>{item.title}</div>
            {item.titleDescriptions.length === 0 ? null : <PopoverComponent
              style={popoverButtonStyle}
              headerTitle={item.title}
              body={item.titleDescriptions}
              image={require('../resources/question_mark.png')} />}
          </div>
        </td>
        {item.value === undefined ? null : <td colSpan={totalColumns - leadingEmptyCells - 1}>
          <div style={sectionCellContainerStyle}>
            <div style={sectionCellTitleStyle}>{item.value}</div>
            {item.valueDescriptions.length === 0 ? null : <PopoverComponent
              style={popoverButtonStyle}
              headerTitle={item.value}
              body={item.valueDescriptions}
              image={require('../resources/question_mark.png')} />}
          </div>
        </td>}
      </tr>
      {item.subitems.map((subitem, index) => renderTableItem(
        subitem, leadingEmptyCells + 1, row + index + 1, totalColumns))}
    </Fragment>
  );
}

/**
 * Returns @c count number of empty cells.
 *
 * @param count Number of empty cells to be displayed.
 */
function emptyCells(count: number) {
  let cells: Array<any> = [];

  for (let i = 0; i < count; ++i) {
    cells.push(<td key={i} />);
  }

  return cells;
}

const sectionTableContainerStyle: CSSProperties = {
  display: 'flex',
  flex: 1,
  marginBottom: '1em',
  borderSpacing: 0,
  border: '0.5px solid #AAAAAA',
};

const sectionTableBodyStyle: CSSProperties = {
  display: 'table',
  flex: 1,
};

const sectionHeaderContainerStyle: CSSProperties = {
  padding: '0.75em',
  backgroundColor: '#EAEAEA',
};

const sectionHeaderTitleStyle: CSSProperties = {
  ...AppFont,
  fontSize: '140%',
  fontWeight: 'bold',
};

const sectionHeaderSubtitleStyle: CSSProperties = {
  ...AppFont,
  fontSize: '90%',
  color: '#6A6A6A',
};

/**
 * Style applied to each row in a table.
 *
 * @param row Row number.
 */
function sectionRowStyle(row: number): CSSProperties {
  return {
    backgroundColor: (row % 2 === 0) ? '#EAEAEA' : 'white',
  };
}

const sectionCellContainerStyle: CSSProperties = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  padding: '0.75em',
};

const sectionCellTitleStyle: CSSProperties = {
  ...AppFont,
  color: '#454545',
  fontSize: '110%',
};

const popoverButtonStyle: CSSProperties = {
  marginLeft: '1em',
  width: '1em',
  height: '1em',
};

export default TableComponent;