export enum FormFieldType {
  TEXT = 'text',
  EMAIL = 'email',
  DROP_DOWN = 'drop_down',
  RADIO_LIST = 'radio_list',
  NUMBER = 'number',
  INTEGER = 'integer',
};

export interface FormSelectionItem {
  key: string,
  title: string,
  value: any,
}

export interface FormItem {
  key: string,
  required: boolean,
  hidden: boolean,
  itemType: any,
  fieldType: FormFieldType,
  title: string,
  placeholder?: string,
  information?: string,
  description?: string,
  maximum?: number,
  minimum?: number,
  selections?: Array<FormSelectionItem>,
}

export interface FormItemFeedback {
  isError: boolean,
  description: string,
}