import React, { CSSProperties } from 'react';

import Button from 'react-bootstrap/Button';

import { AppFont, isLargeWindowWidth, validateText, validateInteger, validateNumber, validateEmail } from '../common';
import { FormFieldType, FormItem, FormItemFeedback, FormSelectionItem } from '../model/Form';
import { residenceStates } from '../resources/residence_states';
import FormComponent from './FormComponent';
import WindowResizeListenerComponent, { WindowResizeListenerComponentState } from './WindowResizeListenerComponent';

/** All available form items for traditional 401(k) form. */
export enum Traditional401kFormItemType {
  MARITAL_STATUS = 'marital_status',
  PERSONAL_INCOME = 'personal_income',
  RESIDENCE_STATE = 'residence_state',
  PERSONAL_CONTRIBUTION = 'personal_contribution',
  RETURN_RATE = 'return_rate',
  CURRENT_AGE = 'current_age',
  COMPANY_CONTRIBUTION = 'company_contribution',
  EXISTING_CONTRIBUTION = 'existing_contribution',
  HAS_EARLY_WITHDRAWL = 'has_early_withdrawal',
  AGE_OF_EARLY_WITHDRAWAL = 'age_of_early_withdrawal',
}

interface Traditional401kFormComponentState extends WindowResizeListenerComponentState {
  /** Current values for each form item. */
  form: Map<string, any>,
  /** Information or warning messages certain form items. */
  feedback: Map<string, FormItemFeedback>,
  /** A set that contains all hidden form items. */
  hiddenItems: Set<string>,
  /** Footer information. */
  footerInformation?: string,
  /** Footer error message. */
  footerError?: string,
}

/** Header title. */
const traditional401kHeaderTitle: string = 'Traditional 401(k) Calculator';
/** Header subtitle. */
const traditional401kHeaderSubtitle: string = 'Answer the following questions to find out your profit. (*) means this field is required.';
/** Error message for missing a required field. */
const traditional401kMissingRequiredFieldError: string = 'This field is required.';
/** Error message for missing a required radio list field. */
const traditional401kMissingRequiredRadioListError: string = 'Please select a value.';
/** Error message for missing a required dropdown list field. */
const traditional401kMissingRequiredDropdownListError: string = 'Please select a value.';
/** Error message for invalid email address entry. */
const traditional401kInvalidEmailFieldError: string = 'Please enter a valid email address.';
/** Title for footer button. */
const traditional401kFooterButtonTitle: string = 'Calculate estimated profit';
/** General error to be displayed in footer if any. */
const traditional401kGeneralError: string = 'There are one or more errors, please fix all highlighted fields and try again.';

class Traditional401kFormComponent extends WindowResizeListenerComponent<
  any, Traditional401kFormComponentState> {
  constructor(props: any) {
    super(props);

    /** Some items have some explanatory information, a.k.a. feedback. */
    let feedback: Map<string, FormItemFeedback> = new Map();
    /** Some items are initially hidden. */
    let hiddenItems: Set<string> = new Set();

    for (const formItem of traditional401kForm) {
      if (formItem.information !== undefined) {
        feedback.set(formItem.key, { isError: false, description: formItem.information });
      }

      if (formItem.hidden) {
        hiddenItems.add(formItem.key);
      }
    }

    this.state = {
      width: window.innerWidth,
      height: window.innerHeight,
      form: new Map(),
      feedback,
      hiddenItems,
    };

    this.radioListDidChangeSelectedItem = this.radioListDidChangeSelectedItem.bind(this);
    this.dropdownListDidChangeSelectedItem = this.dropdownListDidChangeSelectedItem.bind(this);
    this.numberFieldDidChangeText = this.numberFieldDidChangeText.bind(this);
    this.inputFieldDidFocus = this.inputFieldDidFocus.bind(this);
    this.inputFieldDidLoseFocus = this.inputFieldDidLoseFocus.bind(this);
    this.didPressCalculateProfitButton = this.didPressCalculateProfitButton.bind(this);
  }

  /**
   * Sets new selected value to @c form in @c state.
   *
   * @param formItem The form item whose value is being changed.
   * @param selectedItem The new item selected by user. If @c undefined, the previous value will be
   *                     removed from @c form in @c state.
   */
  private radioListDidChangeSelectedItem(formItem: FormItem, selectedItem?: FormSelectionItem) {
    this.setState(prevState => {
      let form: Map<string, any> = prevState.form;
      if (selectedItem === undefined) {
        form.delete(formItem.key);
      } else {
        form.set(formItem.key, selectedItem.value);
      }

      if (formItem.itemType === Traditional401kFormItemType.HAS_EARLY_WITHDRAWL) {
        // Changes to "has_early_withdrawal" field also changes visibility of
        // "age_of_early_withdrawal" field.
        let { hiddenItems, feedback } = prevState;

        if (selectedItem?.value === true) {
          // "Yes" means user DOES want to withdraw early, which means "age_of_early_withdrawal"
          // field should be visible.
          hiddenItems.delete(Traditional401kFormItemType.AGE_OF_EARLY_WITHDRAWAL);
        } else {
          // Otherwise, hide "age_of_early_withdrawal" field.
          hiddenItems.add(Traditional401kFormItemType.AGE_OF_EARLY_WITHDRAWAL);
        }

        if (feedback.get(Traditional401kFormItemType.AGE_OF_EARLY_WITHDRAWAL)?.isError === true) {
          feedback.delete(Traditional401kFormItemType.AGE_OF_EARLY_WITHDRAWAL);
        }

        // Delete previous value for "age_of_early_withdrawal" field.
        form.delete(Traditional401kFormItemType.AGE_OF_EARLY_WITHDRAWAL);

        return {
          ...prevState,
          form,
          feedback,
          hiddenItems,
          footerError: undefined,
        };
      } else {
        return {
          ...prevState,
          form,
          footerError: undefined,
        };
      }
    });
  }

  /**
   * Sets new selected value to @c form in @c state.
   *
   * @param formItem The form item whose value is being changed.
   * @param selectedValue The new item selected by user. If @c undefined, the previous value will be
   *                      removed from @c form in @c state.
   */
  private dropdownListDidChangeSelectedItem(formItem: FormItem, selectedValue?: string) {
    this.setState(prevState => {
      let form: Map<string, any> = prevState.form;

      if (selectedValue === undefined || selectedValue.length === 0) {
        form.delete(formItem.key);
      } else {
        form.set(formItem.key, selectedValue);
      }

      return {
        ...prevState,
        form,
        error: undefined,
      };
    });
  }

  /**
   * Converts @c text to a number and store it in @c form in @c state.
   *
   * @param formItem The form item whose value is being changed.
   * @param text Current text in the input field. This is to be converted to a number and stored in
   *             @c form in @c state.
   */
  private numberFieldDidChangeText(formItem: FormItem, text: string) {
    this.setState(prevState => {
      let form: Map<string, any> = prevState.form;

      if (text.length === 0) {
        form.delete(formItem.key);
      } else {
        form.set(formItem.key, Number(text));
      }

      return {
        ...prevState,
        form,
        error: undefined,
      };
    });
  }

  /**
   * Removes the error message for @c formItem.
   *
   * @param formItem The form item bring focused at the moment.
   */
  private inputFieldDidFocus(formItem: FormItem) {
    this.setState(prevState => {
      let feedback: Map<string, FormItemFeedback> = prevState.feedback;
      if (feedback.get(formItem.key)?.isError === true) {
        feedback.delete(formItem.key);
      }

      return {
        ...prevState,
        feedback,
        error: undefined,
      };
    });
  }

  /**
   * Validates input and displays an error feedback is validation fails.
   *
   * @param formItem The form item that's about to lose focus.
   */
  private inputFieldDidLoseFocus(formItem: FormItem) {
    this.setState(prevState => {
      const form: Map<string, any> = prevState.form;
      let feedback: Map<string, FormItemFeedback> = prevState.feedback;

      if (form.has(formItem.key)) {
        // Value for current form item is found, validate the value.
        if (!this.validateValue(formItem, form.get(formItem.key))) {
          feedback.set(
            formItem.key, { isError: true, description: this.formItemErrorDescription(formItem) });
        } else if (feedback.get(formItem.key)?.isError === true) {
          feedback.delete(formItem.key);
        }
      } else if (formItem.required) {
        // If form item is required, display an error message below the input field.
        feedback.set(
          formItem.key, { isError: true, description: traditional401kMissingRequiredFieldError });
      } else if (feedback.get(formItem.key)?.isError === true) {
        feedback.delete(formItem.key);
      }

      return {
        ...prevState,
        feedback,
      };
    });
  }

  /**
   * Validates @c value and returns @c true if validation succeeds.
   *
   * @param formItem The form item whose value is being validated.
   * @param value The value that's being validated.
   */
  private validateValue(formItem: FormItem, value: any | null | undefined): boolean {
    if (value === undefined || value === null) {
      return !formItem.required;
    } else {
      switch (formItem.fieldType) {
        case FormFieldType.TEXT:
          return validateText(String(value), formItem.maximum, formItem.minimum);
        case FormFieldType.RADIO_LIST:
        case FormFieldType.DROP_DOWN:
          return true;
        case FormFieldType.INTEGER:
          return validateInteger(String(value), formItem.maximum, formItem.minimum);
        case FormFieldType.NUMBER:
          return validateNumber(String(value), formItem.maximum, formItem.minimum);
        case FormFieldType.EMAIL:
          return validateEmail(String(value));
        default:
          return true;
      }
    }
  }

  /**
   * Returns the error description for @c formItem.
   *
   * @param formItem The form item whose value validation failed.
   */
  private formItemErrorDescription(formItem: FormItem): string {
    switch (formItem.fieldType) {
      case FormFieldType.TEXT:
        return this.characterLimitErrorForTextField(formItem.maximum, formItem.minimum);
      case FormFieldType.EMAIL:
        return traditional401kInvalidEmailFieldError;
      case FormFieldType.RADIO_LIST:
        return traditional401kMissingRequiredRadioListError;
      case FormFieldType.DROP_DOWN:
        return traditional401kMissingRequiredDropdownListError;
      case FormFieldType.INTEGER:
        return this.errorDescriptionForIntegerField(formItem.maximum, formItem.minimum);
      case FormFieldType.NUMBER:
        return this.errorDescriptionForNumberField(formItem.maximum, formItem.minimum);
      default:
        return '';
    }
  }

  /**
   * Returns error description for character limit violation.
   *
   * @param maximum Character upper limit. If @c undefined, no upper limit is enforced.
   * @param minimum Character lower limit. If @c undefined, no lower limit is enforced.
   */
  private characterLimitErrorForTextField(maximum: number | undefined,
    minimum: number | undefined): string {
    if (maximum !== undefined && minimum !== undefined) {
      return `Minimum ${minimum} and maximum ${maximum} characters are required.`;
    } else if (maximum !== undefined) {
      return `Maximum ${maximum} characters allowed.`;
    } else if (minimum !== undefined) {
      return `Minimum ${minimum} characters are required.`;
    } else {
      return '';
    }
  }

  /**
   * Returns error description for invalid integer violation.
   *
   * @param maximum Maximum integer allowed. If @c undefined, no upper limit is enforced.
   * @param minimum Minimum integer allowed. If @c undefined, no lower limit is enforced.
   */
  private errorDescriptionForIntegerField(maximum: number | undefined,
    minimum: number | undefined): string {
    if (maximum !== undefined && minimum !== undefined) {
      return `Please enter a whole number between ${minimum} and ${maximum}.`;
    } else if (maximum !== undefined) {
      return `Please enter a whole number less than or equal to ${maximum}.`;
    } else if (minimum !== undefined) {
      return `Please enter a whole number greater than or equal to ${minimum}.`;
    } else {
      return '';
    }
  }

  /**
   * Returns error description for invalid number violation.
   *
   * @param maximum Maximum number allowed. If @c undefined, no upper limit is enforced.
   * @param minimum Minimum number allowed. If @c undefined, no lower limit is enforced.
   */
  private errorDescriptionForNumberField(maximum: number | undefined,
    minimum: number | undefined): string {
    if (maximum !== undefined && minimum !== undefined) {
      return `Please enter a number between ${minimum} and ${maximum}.`;
    } else if (maximum !== undefined) {
      return `Please enter a number less than or equal to ${maximum}.`;
    } else if (minimum !== undefined) {
      return `Please enter a number greater than or equal to ${minimum}.`;
    } else {
      return '';
    }
  }

  /**
   * Calculates profit using user entries.
   *
   * In order to calculate profit, we must ensure that user entries are valid, e.g., they must enter
   * their annual income, and income cannot be negative, etc. This function validates all fields
   * before proceeding with calculations.
   */
  private didPressCalculateProfitButton() {
    // Iterate through all feedback to find an existing error. If found, display a footer error
    // message and skip the following steps.
    this.state.feedback.forEach(feedback => {
      if (feedback.isError) {
        this.setState({ footerError: traditional401kGeneralError });
        return;
      }
    });

    let containsError: boolean = false;
    let feedback: Map<string, FormItemFeedback> = this.state.feedback;
    const { form, hiddenItems } = this.state;

    // If no existing error was found, iterate through all form items to find a potential error.
    // A potential error could be that user missed a required field.
    //
    // It is possible that a form item is required but hidden, e.g., "age_of_early_withdrawal"
    // field. This field is hidden until user indicates that they want to withdraw from their
    // 401(k) early (before they retire). However, if they want to withdraw early, this field must
    // be required. Therefore, it should be taken into account that it's not necessary that a
    // required field must be filled.
    for (const formItem of traditional401kForm) {
      if (formItem.required && !hiddenItems.has(formItem.key) && !form.has(formItem.key)) {
        feedback.set(
          formItem.key, { isError: true, description: traditional401kMissingRequiredFieldError });
        containsError = true;
      }
    }

    if (containsError) {
      this.setState({ feedback, footerError: traditional401kGeneralError });
      return;
    }

    // No error was found, proceed with constructing query items.
    let data: Record<string, any> = {};
    for (const formItem of traditional401kForm) {
      if (form.has(formItem.key)) {
        data[formItem.key.toLowerCase()] = form.get(formItem.key);
      }
    }
    const params: URLSearchParams = new URLSearchParams(data);

    this.props.history.push(`/traditional/profit?${params.toString()}`);
  }

  render() {
    const { width, form, feedback, hiddenItems, footerInformation, footerError } = this.state;

    return (
      <div style={traditional401kContainerStyle(width)}>
        <div>
          <div style={traditional401kHeaderTitleStyle}>{traditional401kHeaderTitle}</div>
          <div style={traditional401kHeaderSubtitleStyle}>{traditional401kHeaderSubtitle}</div>
        </div>
        <FormComponent
          style={traditional401kFormContainerStyle}
          form={traditional401kForm}
          currentValues={form}
          feedback={feedback}
          hiddenItems={hiddenItems}
          formGroupContainerStyle={traditional401kFormGroupContainerStyle(width)}
          formItemContainerStyle={traditional401kFormItemContainerStyle(width)}
          radioListDidChangeSelectedItem={this.radioListDidChangeSelectedItem}
          dropdownListDidChangeSelectedItem={this.dropdownListDidChangeSelectedItem}
          numberFieldDidChangeText={this.numberFieldDidChangeText}
          inputFieldDidFocus={this.inputFieldDidFocus}
          inputFieldDidLoseFocus={this.inputFieldDidLoseFocus}
        />
        <div style={traditional401kFooterFeedbackContainerStyle}>
          {footerInformation === undefined ? null : <div
            style={traditional401kFooterInformationStyle}>
            {footerInformation}
          </div>}
          {footerError === undefined ? null : <div
            style={traditional401kFooterErrorStyle}>
            {footerError}
          </div>}
        </div>
        <Button
          style={traditional401kFooterButtonTitleStyle}
          onClick={this.didPressCalculateProfitButton}
        >
          {traditional401kFooterButtonTitle}
        </Button>
      </div>
    );
  }
}

/** Maximum personal income, ten million. */
const maximumPersonalIncome: number = 10_000_000;
/** Maximum traditional 401(k) annual personal contribution. */
const maximumTraditional401kContribution: number = 19_500;
/** Maximum annual return rate on traditional 401(k) investment. */
const maximumTraditional401kReturnRate: number = 15;
/** Maximum age. */
const maximumAge: number = 59;
/** Minimum age. */
const minimumAge: number = 18;
/** Maximum existing contribution to traditional 401(k), one million */
const maximumTraditional401kExistingContribution: number = 1_000_000;
/**
 * Number of form items rendered on each row. It is possible that the last row is not fulfilled if
 * the total number of form items is not a multiple of this number.
 */
const numberOfFormItemsPerRow: number = 2;

/** Form for traditional 401(k) calculator. */
const traditional401kForm: Array<FormItem> = [
  {
    key: Traditional401kFormItemType.MARITAL_STATUS,
    hidden: false,
    itemType: Traditional401kFormItemType.MARITAL_STATUS,
    fieldType: FormFieldType.RADIO_LIST,
    title: 'Are you married?',
    description: 'Your marital status along with income decide your tax brackets.',
    required: true,
    minimum: 1,
    maximum: 1,
    selections: [
      {
        key: 'yes',
        title: 'Yes',
        value: true,
      },
      {
        key: 'no',
        title: 'No',
        value: false
      },
    ]
  },
  {
    key: Traditional401kFormItemType.PERSONAL_INCOME,
    hidden: false,
    itemType: Traditional401kFormItemType.PERSONAL_INCOME,
    fieldType: FormFieldType.NUMBER,
    title: 'What\'s your annual personal income?',
    placeholder: 'e.g. $50,000',
    description: 'Your annual income along with marital status decide your tax brackets. We assume your annual income doesn’t change in the future.',
    required: true,
    maximum: maximumPersonalIncome,
    minimum: 0,
  },
  {
    key: Traditional401kFormItemType.RESIDENCE_STATE,
    hidden: false,
    itemType: Traditional401kFormItemType.RESIDENCE_STATE,
    fieldType: FormFieldType.DROP_DOWN,
    title: 'Which state do you live in?',
    required: true,
    selections: residenceStates.map(residenceState => {
      return {
        key: residenceState.abbreviation,
        title: residenceState.name,
        value: residenceState.abbreviation,
      };
    }),
  },
  {
    key: Traditional401kFormItemType.PERSONAL_CONTRIBUTION,
    hidden: false,
    itemType: Traditional401kFormItemType.PERSONAL_CONTRIBUTION,
    fieldType: FormFieldType.NUMBER,
    title: 'How much do you plan to invest into 401(k) each year?',
    placeholder: 'e.g. $8,000',
    description: `Maximum allowed by the IRS for 2020 is $${maximumTraditional401kContribution}. Please enter a number smaller than this and we will use it as your annual contribution to calculate your profit.`,
    required: true,
    maximum: maximumTraditional401kContribution,
    minimum: 0,
  },
  {
    key: Traditional401kFormItemType.RETURN_RATE,
    hidden: false,
    itemType: Traditional401kFormItemType.RETURN_RATE,
    fieldType: FormFieldType.NUMBER,
    title: 'What is the annual return rate on your 401(k) investment?',
    placeholder: 'e.g. 5%',
    description: '401(k) is an investment account, i.e., a part of your paycheck is taken out and invested into various plans such as mutual funds, stocks, bonds and money market investments, etc. A common return rate could be 4% - 8%.',
    required: true,
    maximum: maximumTraditional401kReturnRate,
    minimum: 0,
  },
  {
    key: Traditional401kFormItemType.CURRENT_AGE,
    hidden: false,
    itemType: Traditional401kFormItemType.CURRENT_AGE,
    fieldType: FormFieldType.NUMBER,
    title: 'What\'s your age?',
    description: 'You can start receiving money from your 401(k) account when you reach 59 and 1/2 years old. You can, however, withdraw earlier than that, but you may be subject to a penalty. We use this field to calculate your profit by the time you reach 59 and 1/2 years old.',
    required: true,
    maximum: maximumAge,
    minimum: minimumAge,
  },
  {
    key: Traditional401kFormItemType.COMPANY_CONTRIBUTION,
    hidden: false,
    itemType: Traditional401kFormItemType.COMPANY_CONTRIBUTION,
    fieldType: FormFieldType.NUMBER,
    title: 'How much does your company contribute to your 401(k)?',
    placeholder: 'e.g. $4,000',
    description: 'Some companies match their employees’ contributions by 10%, i.e., if you contribute $10,000, your company will contribute another $1,000 to your 401(k) account. In this case, enter $1,000, which is the amount that your company contributes.',
    required: false,
    maximum: maximumTraditional401kContribution,
    minimum: 0,
  },
  {
    key: Traditional401kFormItemType.EXISTING_CONTRIBUTION,
    hidden: false,
    itemType: Traditional401kFormItemType.EXISTING_CONTRIBUTION,
    fieldType: FormFieldType.NUMBER,
    title: 'How much do you already have in your 401(k) account?',
    placeholder: 'e.g. $100,000',
    required: false,
    maximum: maximumTraditional401kExistingContribution,
    minimum: 0,
  },
  {
    key: Traditional401kFormItemType.HAS_EARLY_WITHDRAWL,
    hidden: false,
    itemType: Traditional401kFormItemType.HAS_EARLY_WITHDRAWL,
    fieldType: FormFieldType.RADIO_LIST,
    title: 'Do you plan to make an early withdrawal?',
    information: 'If you select "Yes", we will ask you to specify at what age do you plan to make the early withdrawal and we assume you will withdraw ALL the money in your 401(k) account at that age.',
    description: 'Early withdrawal means that you withdraw from your 401(k) account before you reach 59 and 1/2 years old. You are subject to a penalty on top of regular taxes.',
    required: false,
    minimum: 1,
    maximum: 1,
    selections: [
      {
        key: 'yes',
        title: 'Yes',
        value: true
      },
      {
        key: 'no',
        title: 'No',
        value: false
      }
    ],
  },
  {
    key: Traditional401kFormItemType.AGE_OF_EARLY_WITHDRAWAL,
    hidden: true,
    itemType: Traditional401kFormItemType.AGE_OF_EARLY_WITHDRAWAL,
    fieldType: FormFieldType.NUMBER,
    title: 'At what age do you plan to make an early withdrawal?',
    description: 'We assume that you will withdraw ALL the money in your 401(k) account at this age.',
    required: true,
    maximum: maximumAge,
    minimum: minimumAge,
  },
];

/**
 * Style for global container.
 *
 * On a smaller device, content margin should be less significant than on a larger device.
 *
 * @param width Inner width of @c window.
 */
function traditional401kContainerStyle(width: number): CSSProperties {
  if (isLargeWindowWidth(width)) {
    return {
      marginLeft: '20%',
      marginRight: '20%',
      marginTop: '5%',
      marginBottom: '5%',
    };
  } else {
    return {
      margin: '5%',
    };
  }
}

const traditional401kHeaderTitleStyle: CSSProperties = {
  ...AppFont,
  fontSize: '250%',
  fontWeight: 'bold',
};

const traditional401kHeaderSubtitleStyle: CSSProperties = {
  ...AppFont,
  fontSize: '90%',
  color: '#666666',
};

const traditional401kFormContainerStyle: CSSProperties = {
  marginTop: '5%',
};

/**
 * Style applied to container for a row of form items.
 *
 * @param width Inner width of @c window.
 */
function traditional401kFormGroupContainerStyle(width: number): CSSProperties {
  if (isLargeWindowWidth(width)) {
    return {
      display: 'flex',
      flexDirection: 'row',
    };
  } else {
    return {
      display: 'flex',
      flexDirection: 'column',
    };
  }
}

/**
 * Style applied to container for each form item.
 *
 * @param width Inner width of @c window.
 */
function traditional401kFormItemContainerStyle(width: number): CSSProperties {
  if (isLargeWindowWidth(width)) {
    const gapSize: number = 1.5;
    const width: number = (100 - (numberOfFormItemsPerRow - 1) * gapSize) / numberOfFormItemsPerRow;
    return {
      display: 'flex',
      flexDirection: 'column',
      width: `${width}%`,
      marginRight: `${gapSize}%`,
      marginBottom: '1em',
    };
  } else {
    return {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      marginBottom: '1em',
    };
  }
}

const traditional401kFooterFeedbackContainerStyle: CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  marginTop: '2%',
};

const traditional401kFooterInformationStyle: CSSProperties = {
  ...AppFont,
  color: '#008105',
  fontSize: '90%',
};

const traditional401kFooterErrorStyle: CSSProperties = {
  ...AppFont,
  color: '#E12F40',
  fontSize: '90%',
};

const traditional401kFooterButtonTitleStyle: CSSProperties = {
  ...AppFont,
  fontSize: '120%',
  color: '#FFFFFF',
  width: '100%',
  marginTop: '2%',
};

export default Traditional401kFormComponent;