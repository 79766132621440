import React, { CSSProperties } from 'react';

import { OverlayTrigger, Popover } from 'react-bootstrap';
import { AppFont } from '../common';

interface PopoverComponentProps {
  style?: CSSProperties,
  /** Title for this popover component. */
  title?: string,
  /** Instead of title, an icon image can also be accepted for this popover component. */
  image?: any,
  /** Header title for when popover displays */
  headerTitle: string,
  /** An array of strings to be displayed as body of this popover component. */
  body: Array<string>,
}

function PopoverComponent(props: PopoverComponentProps) {
  const { headerTitle, body, title, image, style } = props;

  return (
    <OverlayTrigger
      trigger={['hover', 'click', 'focus']}
      placement='auto'
      overlay={popover(headerTitle, body)}
    >
      {toggleButton(title, image, style)}
    </OverlayTrigger>
  );
}

/**
 * Popover component that get rendered when popover button is focused.
 *
 * @param headerTitle Header title for when popover displays.
 * @param body An array of strings to be displayed as body of this popover component.
 */
function popover(headerTitle: string, body: Array<string>): JSX.Element {
  return (
    <Popover id='popover-basic'>
      <Popover.Title as='h3' style={popoverComponentHeaderTitleStyle}>{headerTitle}</Popover.Title>
      {body.map((value, index) => <Popover.Content key={index} style={popoverComponentBodyStyle}>
        {value}
      </Popover.Content>)}
    </Popover>
  );
}

/**
 * Returns a button that triggers this popover component when focused.
 *
 * @param title Title for this button. If @c undefined, @c image icon is used.
 * @param image Icon image for this button. If @c undefined, an empty @c div is rendered.
 * @param style Style applied to this button.
 */
function toggleButton(title?: string, image?: any, style?: CSSProperties): JSX.Element {
  if (title !== undefined) {
    return <button style={style}>{title}</button>;
  } else if (image !== undefined) {
    return <input type='image' alt='' src={image} style={style} />
  } else {
    return <div />;
  }
}

const popoverComponentHeaderTitleStyle: CSSProperties = {
  ...AppFont,
  fontWeight: 'bold',
};

const popoverComponentBodyStyle: CSSProperties = {
  ...AppFont,
  color: '#505050',
};

export default PopoverComponent;