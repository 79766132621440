import React, { CSSProperties } from 'react';

import Form from 'react-bootstrap/Form';
import { AppFont } from '../common';

import { FormFieldType, FormItem, FormItemFeedback, FormSelectionItem } from '../model/Form';

interface FormComponentProps {
  /** Style to be applied to this component. */
  style?: CSSProperties,
  /** Style to be applied to each row of the form. */
  formGroupContainerStyle?: CSSProperties,
  /** Style to be applied to each form item. */
  formItemContainerStyle?: CSSProperties,
  /** An array of form items that make up the form. */
  form: Array<FormItem>,
  /** Current values for all form items in the form. */
  currentValues: Map<string, any>,
  /** Feedback to be displayed under each form item to provide information or warnings. */
  feedback: Map<string, FormItemFeedback>,
  /** A set of hidden form items. These items will not be rendered. */
  hiddenItems: Set<string>,

  /**
   * Operations to be executed when text in a text field changes.
   *
   * @param formItem The form item that this text field belongs to.
   * @param text Current text displayed in text field.
   */
  textFieldDidChangeText?: (formItem: FormItem, text: string) => void,
  /**
   * Operations to be executed when selected item changes in a radio list form item.
   *
   * @param formItem The form item that this radio list field belongs to.
   * @param selectedItem The new item selected by user. When @c undefined, it means user un-selected
   *                     what they selected previously.
   */
  radioListDidChangeSelectedItem?: (formItem: FormItem, selectedItem?: FormSelectionItem) => void,
  /**
   * Operations to be executed when selected item changes in a dropdown list form item.
   *
   * @param formItem The form item that this dropdown list field belongs to.
   * @param selectedValue The new item selected by user. When @c undefined, it means user
   *                      un-selected what they selected previously.
   */
  dropdownListDidChangeSelectedItem?: (formItem: FormItem, selectedValue?: string) => void,
  /**
   * Operations to be executed when text in a number field changes.
   *
   * @param formItem The form item that this text field belongs to.
   * @param text Current text displayed in text field.
   */
  numberFieldDidChangeText?: (formItem: FormItem, text: string) => void,
  /**
   * Operations to be executed when a form item is focused.
   *
   * @param formItem The form item that this input field belongs to.
   */
  inputFieldDidFocus?: (formItem: FormItem) => void,
  /**
   * Operations to be executed when a form item loses focus.
   *
   * @param formItem The form item that this input field belongs to.
   */
  inputFieldDidLoseFocus?: (formItem: FormItem) => void,
}

/**
 * Number of form items rendered on each row. It is possible that the last row is not fulfilled if
 * the total number of form items is not a multiple of this number.
 */
const numberOfFormItemsPerRow: number = 2;
/**
 * Every dropdown list's first option is this, but if user selects this option, there is not value
 * associated, which means, user un-selected what they selected previously.
 */
const dropdownDefaultOption: string = '--select an option--';

/**
 * Renders a form given all the form items.
 *
 * @param props Necessary information needed to render a form. See definition of
 *              @c FormComponentProps.
 */
function FormComponent(props: FormComponentProps) {
  const {
    style,
    form,
    hiddenItems,
    feedback,
    currentValues,
    formGroupContainerStyle,
    formItemContainerStyle,
    textFieldDidChangeText,
    radioListDidChangeSelectedItem,
    dropdownListDidChangeSelectedItem,
    numberFieldDidChangeText,
    inputFieldDidFocus,
    inputFieldDidLoseFocus } = props;

  return (
    <div style={style}>
      {renderFormGroups(form,
        hiddenItems,
        feedback,
        currentValues,
        formGroupContainerStyle,
        formItemContainerStyle,
        textFieldDidChangeText,
        radioListDidChangeSelectedItem,
        dropdownListDidChangeSelectedItem,
        numberFieldDidChangeText,
        inputFieldDidFocus,
        inputFieldDidLoseFocus)}
    </div>
  );
}

/**
 * Divides all form items into several groups, each of which contains up to
 * @c numberOfFormItemsPerRow number of form items.
 *
 * @param form An array that contains all form items that make up the form.
 * @param hiddenItems A set of form items that should be hidden.
 * @param feedback Feedback form all form items in current form.
 * @param currentValues Current values for all form items in the form.
 * @param formGroupContainerStyle Style for the group container.
 * @param formItemContainerStyle Style for the form item container.
 * @param textFieldDidChangeText Operations to be executed when text changes.
 * @param radioListDidChangeSelectedItem Operations to be executed when selected item changes.
 * @param dropdownListDidChangeSelectedItem Operations to be executed when selected item changes.
 * @param numberFieldDidChangeText Operations to be executed when text in a number field changes.
 * @param inputFieldDidFocus Operations to be executed when @c formItem is focused.
 * @param inputFieldDidLoseFocus Operations to be executed when @c formItem loses focus.
 */
function renderFormGroups(form: Array<FormItem>,
  hiddenItems: Set<string>,
  feedback: Map<string, FormItemFeedback>,
  currentValues: Map<string, any>,
  formGroupContainerStyle?: CSSProperties,
  formItemContainerStyle?: CSSProperties,
  textFieldDidChangeText?: (formItem: FormItem, text: string) => void,
  radioListDidChangeSelectedItem?: (formItem: FormItem, selectedItem?: FormSelectionItem) => void,
  dropdownListDidChangeSelectedItem?: (formItem: FormItem, selectedValue?: string) => void,
  numberFieldDidChangeText?: (formItem: FormItem, text: string) => void,
  inputFieldDidFocus?: (formItem: FormItem) => void,
  inputFieldDidLoseFocus?: (formItem: FormItem) => void) {
  let groups: Array<any> = [];

  for (let i = 0; i < form.length;) {
    // Form items to be rendered in current row.
    let items: Array<FormItem> = [];

    for (let j = i; j < form.length; ++j) {
      if (hiddenItems.has(form[j].key)) {
        // no-op. If current item is hidden, do nothing.
      } else {
        items.push(form[j]);
      }

      // Increment index of next form item to be rendered.
      i = j + 1;

      // Already reached maximum allowed form items in a single row, proceed with rendering.
      if (items.length >= numberOfFormItemsPerRow) {
        break;
      }
    }

    groups.push(renderFormGroup(items,
      feedback,
      currentValues,
      formGroupContainerStyle,
      formItemContainerStyle,
      textFieldDidChangeText,
      radioListDidChangeSelectedItem,
      dropdownListDidChangeSelectedItem,
      numberFieldDidChangeText,
      inputFieldDidFocus,
      inputFieldDidLoseFocus));
  }

  return groups;
}

/**
 * Renders a row of form items as a group.
 *
 * @param group An array of form items to be rendered in a single row.
 * @param feedback Feedback form all form items in current form.
 * @param currentValues Current values for all form items in the form.
 * @param formGroupContainerStyle Style for the group container.
 * @param formItemContainerStyle Style for the form item container.
 * @param textFieldDidChangeText Operations to be executed when text changes.
 * @param radioListDidChangeSelectedItem Operations to be executed when selected item changes.
 * @param dropdownListDidChangeSelectedItem Operations to be executed when selected item changes.
 * @param numberFieldDidChangeText Operations to be executed when text in a number field changes.
 * @param inputFieldDidFocus Operations to be executed when @c formItem is focused.
 * @param inputFieldDidLoseFocus Operations to be executed when @c formItem loses focus.
 */
function renderFormGroup(group: Array<FormItem>,
  feedback: Map<string, FormItemFeedback>,
  currentValues: Map<string, any>,
  formGroupContainerStyle?: CSSProperties,
  formItemContainerStyle?: CSSProperties,
  textFieldDidChangeText?: (formItem: FormItem, text: string) => void,
  radioListDidChangeSelectedItem?: (formItem: FormItem, selectedItem?: FormSelectionItem) => void,
  dropdownListDidChangeSelectedItem?: (formItem: FormItem, selectedValue?: string) => void,
  numberFieldDidChangeText?: (formItem: FormItem, text: string) => void,
  inputFieldDidFocus?: (formItem: FormItem) => void,
  inputFieldDidLoseFocus?: (formItem: FormItem) => void) {
  if (group.length === 0) {
    return null;
  } else {
    // Use the key of the first item in the group as the key for the entire group.
    return (
      <div
        key={group[0].key}
        style={formGroupContainerStyle ?? defaultFormGroupContainerStyle}
      >
        {group.map(formItem => renderFormItem(formItem,
          feedback,
          currentValues,
          formItemContainerStyle,
          textFieldDidChangeText,
          radioListDidChangeSelectedItem,
          dropdownListDidChangeSelectedItem,
          numberFieldDidChangeText,
          inputFieldDidFocus,
          inputFieldDidLoseFocus))}
      </div>
    );
  }
}

/**
 * Renders a single form item.
 *
 * @param formItem The form item to be rendered.
 * @param feedback Feedback form all form items in current form.
 * @param currentValues Current values for all form items in the form.
 * @param formItemContainerStyle Style for the form item container.
 * @param textFieldDidChangeText Operations to be executed when text changes.
 * @param radioListDidChangeSelectedItem Operations to be executed when selected item changes.
 * @param dropdownListDidChangeSelectedItem Operations to be executed when selected item changes.
 * @param numberFieldDidChangeText Operations to be executed when text in a number field changes.
 * @param inputFieldDidFocus Operations to be executed when @c formItem is focused.
 * @param inputFieldDidLoseFocus Operations to be executed when @c formItem loses focus.
 */
function renderFormItem(formItem: FormItem,
  feedback: Map<string, FormItemFeedback>,
  currentValues: Map<string, any>,
  formItemContainerStyle?: CSSProperties,
  textFieldDidChangeText?: (formItem: FormItem, text: string) => void,
  radioListDidChangeSelectedItem?: (formItem: FormItem, selectedItem?: FormSelectionItem) => void,
  dropdownListDidChangeSelectedItem?: (formItem: FormItem, selectedValue?: string) => void,
  numberFieldDidChangeText?: (formItem: FormItem, text: string) => void,
  inputFieldDidFocus?: (formItem: FormItem) => void,
  inputFieldDidLoseFocus?: (formItem: FormItem) => void) {
  const formItemFeedback: FormItemFeedback | undefined = feedback.get(formItem.key);

  return (
    <div key={formItem.key} style={formItemContainerStyle ?? defaultFormItemContainerStyle}>
      <div style={formItemTitleStyle}>
        {formItem.title}{formItem.required ? <span style={formItemAsteriskStyle}>*</span> : null}
      </div>
      {renderFormItemInputView(formItem,
        feedback,
        currentValues,
        textFieldDidChangeText,
        radioListDidChangeSelectedItem,
        dropdownListDidChangeSelectedItem,
        numberFieldDidChangeText,
        inputFieldDidFocus,
        inputFieldDidLoseFocus)}
      {formItemFeedback === undefined ? null : <div
        style={formItemFeedbackStyle(formItemFeedback.isError)}>
        {formItemFeedback.description}
      </div>}
      {formItem.description === undefined ? null : <div style={formItemDescriptionStyle}>
        {formItem.description}
      </div>}
    </div>
  );
}

/**
 * Renders the input view for @c formItem.
 *
 * @param formItem Form item to be rendered.
 * @param feedback Feedback form all form items in current form.
 * @param currentValues Current values for all form items in the form.
 * @param textFieldDidChangeText Operations to be executed when text changes.
 * @param radioListDidChangeSelectedItem Operations to be executed when selected item changes.
 * @param dropdownListDidChangeSelectedItem Operations to be executed when selected item changes.
 * @param numberFieldDidChangeText Operations to be executed when text in a number field changes.
 * @param inputFieldDidFocus Operations to be executed when @c formItem is focused.
 * @param inputFieldDidLoseFocus Operations to be executed when @c formItem loses focus.
 */
function renderFormItemInputView(formItem: FormItem,
  feedback: Map<string, FormItemFeedback>,
  currentValues: Map<string, any>,
  textFieldDidChangeText?: (formItem: FormItem, text: string) => void,
  radioListDidChangeSelectedItem?: (formItem: FormItem, selectedItem?: FormSelectionItem) => void,
  dropdownListDidChangeSelectedItem?: (formItem: FormItem, selectedValue?: string) => void,
  numberFieldDidChangeText?: (formItem: FormItem, text: string) => void,
  inputFieldDidFocus?: (formItem: FormItem) => void,
  inputFieldDidLoseFocus?: (formItem: FormItem) => void) {
  switch (formItem.fieldType) {
    case FormFieldType.TEXT:
    case FormFieldType.EMAIL:
      return renderTextFieldForFormItem(formItem,
        feedback,
        currentValues,
        textFieldDidChangeText,
        inputFieldDidFocus,
        inputFieldDidLoseFocus);
    case FormFieldType.RADIO_LIST:
      return renderRadioListForFormItem(formItem,
        feedback,
        currentValues,
        radioListDidChangeSelectedItem,
        inputFieldDidFocus,
        inputFieldDidLoseFocus);
    case FormFieldType.DROP_DOWN:
      return renderDropdownListForFormItem(formItem,
        feedback,
        dropdownListDidChangeSelectedItem,
        inputFieldDidFocus,
        inputFieldDidLoseFocus);
    case FormFieldType.NUMBER:
    case FormFieldType.INTEGER:
      return renderNumberFieldForFormItem(formItem,
        feedback,
        currentValues,
        numberFieldDidChangeText,
        inputFieldDidFocus,
        inputFieldDidLoseFocus);
    default:
      return null;
  }
}

/**
 * Renders text input view for @c formItem.
 *
 * @param formItem Form item whose input view is to be rendered.
 * @param feedback Feedback form all form items in current form.
 * @param currentValues Current values for all form items in the form.
 * @param textFieldDidChangeText Operations to be executed when text changes.
 * @param inputFieldDidFocus Operations to be executed when @c formItem is focused.
 * @param inputFieldDidLoseFocus Operations to be executed when @c formItem loses focus.
 */
function renderTextFieldForFormItem(formItem: FormItem,
  feedback: Map<string, FormItemFeedback>,
  currentValues: Map<string, any>,
  textFieldDidChangeText?: (formItem: FormItem, text: string) => void,
  inputFieldDidFocus?: (formItem: FormItem) => void,
  inputFieldDidLoseFocus?: (formItem: FormItem) => void) {
  return (
    <Form.Control
      type={formItem.fieldType}
      placeholder={formItem.placeholder}
      style={formItemInputFieldStyle(feedback.get(formItem.key))}
      value={currentValues.get(formItem.key) ?? ''}
      onChange={(e) => {
        if (textFieldDidChangeText !== undefined) {
          textFieldDidChangeText(formItem, e.target.value);
        }
      }}
      onFocus={() => {
        if (inputFieldDidFocus !== undefined) {
          inputFieldDidFocus(formItem);
        }
      }}
      onBlur={() => {
        if (inputFieldDidLoseFocus !== undefined) {
          inputFieldDidLoseFocus(formItem);
        }
      }}
    />
  );
}

/**
 * Renders radio list input view for @c formItem.
 *
 * @param formItem Form item whose input view is to be rendered.
 * @param feedback Feedback form all form items in current form.
 * @param currentValues Current values for all form items in the form.
 * @param radioListDidChangeSelectedItem Operations to be executed when selected item changes.
 * @param inputFieldDidFocus Operations to be executed when @c formItem is focused.
 * @param inputFieldDidLoseFocus Operations to be executed when @c formItem loses focus.
 */
function renderRadioListForFormItem(formItem: FormItem,
  feedback: Map<string, FormItemFeedback>,
  currentValues: Map<string, any>,
  radioListDidChangeSelectedItem?: (formItem: FormItem, selectedItem?: FormSelectionItem) => void,
  inputFieldDidFocus?: (formItem: FormItem) => void,
  inputFieldDidLoseFocus?: (formItem: FormItem) => void) {
  if (formItem.selections === undefined || formItem.selections.length === 0) {
    return null;
  } else {
    const numberOfRadioItems: number = formItem.selections.length;
    return (
      <div style={formItemRadioListContainerStyle(feedback.get(formItem.key))}>
        {formItem.selections.map(selection => <Form.Check
          key={selection.key}
          type='radio'
          style={formItemRadioListItemStyle(numberOfRadioItems)}
          label={selection.title}
          checked={currentValues.get(formItem.key) === selection.value}
          onChange={() => {
            if (radioListDidChangeSelectedItem !== undefined) {
              radioListDidChangeSelectedItem(formItem, selection);
            }
          }}
          onFocus={() => {
            if (inputFieldDidFocus !== undefined) {
              inputFieldDidFocus(formItem);
            }
          }}
          onBlur={() => {
            if (inputFieldDidLoseFocus !== undefined) {
              inputFieldDidLoseFocus(formItem);
            }
          }}
        />)}
      </div>
    );
  }
}

/**
 * Renders dropdown list input view for @c formItem.
 *
 * @param formItem Form item whose input view is to be rendered.
 * @param feedback Feedback form all form items in current form.
 * @param dropdownListDidChangeSelectedItem Operations to be executed when selected item changes.
 * @param inputFieldDidFocus Operations to be executed when @c formItem is focused.
 * @param inputFieldDidLoseFocus Operations to be executed when @c formItem loses focus.
 */
function renderDropdownListForFormItem(formItem: FormItem,
  feedback: Map<string, FormItemFeedback>,
  dropdownListDidChangeSelectedItem?: (formItem: FormItem, selectedValue?: string) => void,
  inputFieldDidFocus?: (formItem: FormItem) => void,
  inputFieldDidLoseFocus?: (formItem: FormItem) => void) {
  if (formItem.selections === undefined || formItem.selections.length === 0) {
    return null;
  } else {
    return (
      <select
        style={formItemInputFieldStyle(feedback.get(formItem.key))}
        onChange={(e) => {
          if (dropdownListDidChangeSelectedItem !== undefined) {
            dropdownListDidChangeSelectedItem(formItem, e.target.value);
          }
        }}
        onFocus={() => {
          if (inputFieldDidFocus !== undefined) {
            inputFieldDidFocus(formItem);
          }
        }}
        onBlur={() => {
          if (inputFieldDidLoseFocus !== undefined) {
            inputFieldDidLoseFocus(formItem);
          }
        }}
      >
        <option label={dropdownDefaultOption} value='' />
        {formItem.selections.map(selection => <option
          key={selection.key}
          value={selection.value}
          label={selection.title}
        >
          {selection.title}
        </option>)}
      </select>
    );
  }
}

/**
 * Renders number input view for @c formItem.
 *
 * @param formItem Form item whose input view is to be rendered.
 * @param feedback Feedback form all form items in current form.
 * @param currentValues Current values for all form items in the form.
 * @param numberFieldDidChangeText Operations to be executed when text in a number field changes.
 * @param inputFieldDidFocus Operations to be executed when @c formItem is focused.
 * @param inputFieldDidLoseFocus Operations to be executed when @c formItem loses focus.
 */
function renderNumberFieldForFormItem(formItem: FormItem,
  feedback: Map<string, FormItemFeedback>,
  currentValues: Map<string, any>,
  numberFieldDidChangeText?: (formItem: FormItem, text: string) => void,
  inputFieldDidFocus?: (formItem: FormItem) => void,
  inputFieldDidLoseFocus?: (formItem: FormItem) => void) {
  return (
    <Form.Control
      type='number'
      placeholder={formItem.placeholder}
      style={formItemInputFieldStyle(feedback.get(formItem.key))}
      value={currentValues.get(formItem.key) ?? ''}
      onChange={(e) => {
        if (numberFieldDidChangeText !== undefined) {
          numberFieldDidChangeText(formItem, e.target.value);
        }
      }}
      onFocus={() => {
        if (inputFieldDidFocus !== undefined) {
          inputFieldDidFocus(formItem);
        }
      }}
      onBlur={() => {
        if (inputFieldDidLoseFocus !== undefined) {
          inputFieldDidLoseFocus(formItem);
        }
      }}
    />
  );
}

/** Group container style applied when @c formGroupContainerStyle is @c undefined. */
const defaultFormGroupContainerStyle: CSSProperties = {
  display: 'flex',
  flexDirection: 'row',
};

/** Form item container style applied when @c formItemContainerStyle is @c undefined. */
const defaultFormItemContainerStyle: CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  marginBottom: '1em',
};

const formItemTitleStyle: CSSProperties = {
  ...AppFont,
  display: 'flex',
  flexDirection: 'row',
  fontSize: '120%',
};

const formItemAsteriskStyle: CSSProperties = {
  ...formItemTitleStyle,
  color: 'red',
};

/**
 * Style applied to input field for a form item.
 *
 * @param feedback Feedback for form item.
 */
function formItemInputFieldStyle(feedback?: FormItemFeedback): CSSProperties {
  const style: CSSProperties = {
    ...AppFont,
    borderWidth: 1,
    borderColor: '#CACACA',
    borderRadius: 5,
    width: '75%',
    height: '2em',
    marginTop: '0.5em'
  };

  if (feedback === undefined) {
    return style;
  } else {
    return {
      ...style,
      borderColor: feedback.isError ? '#E12F40' : '#008105',
    };
  }
}

/**
 * Style applied to radio list field for a form item.
 *
 * @param feedback Feedback for form item.
 */
function formItemRadioListContainerStyle(feedback?: FormItemFeedback): CSSProperties {
  return {
    ...formItemInputFieldStyle(feedback),
    display: 'flex',
    flexDirection: 'row',
  };
}

/**
 * Style applied to each radio item in a radio list.
 *
 * @param count Number of radio items in the radio list.
 */
function formItemRadioListItemStyle(count: number): CSSProperties {
  const gapSize: number = 2;
  const width: number = (100 - (count - 1) * gapSize) / count;

  return {
    ...AppFont,
    width: `${width}%`,
    marginRight: `${gapSize}%`,
  };
}

/**
 * Style applied to feedback under each form item.
 *
 * @param isError Whether or not this feedback is an error message.
 */
function formItemFeedbackStyle(isError: boolean): CSSProperties {
  return {
    ...AppFont,
    fontSize: '90%',
    color: isError ? '#E12F40' : '#008105',
  };
}

const formItemDescriptionStyle: CSSProperties = {
  ...AppFont,
  fontSize: '85%',
  color: '#666666',
  marginTop: '0.5em',
};

export default FormComponent;