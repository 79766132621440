import React, { CSSProperties } from 'react';

import WindowResizeListenerComponent, { WindowResizeListenerComponentState } from './WindowResizeListenerComponent';
import { Traditional401kFormItemType } from './Traditional401kFormComponent';
import { residenceStates } from '../resources/residence_states';
import { AppFont, calculateFederalTaxLiability, calculateFICATaxLiability, calculateStateTaxLiability, calculateTotal401kValue, formatCurrency, isLargeWindowWidth } from '../common';
import TableComponent, { SectionTable } from './TableComponent';

interface Traditional401kProfitComponentState extends WindowResizeListenerComponentState {
  married: boolean,
  personalIncome: number,
  residenceState: string,
  personalContribution: number,
  returnRate: number,
  currentAge: number,
  companyContribution: number,
  existingContribution: number,
  hasEarlyWithdrawal: boolean,
  ageOfEarlyWithdrawal: number,
}

interface Traditional401kProfitPrediction {
  /** Number of years across the investment period. */
  years: number,
  /** Total regular personal income, which is user's income after their contribution to 401(k) */
  totalRegularPersonalIncome: number,
  /**
   * Total taxable income, including regular taxable personal income and amount of 401(k)
   * withdrawal.
   */
  totalTaxableIncome: number,
  /** Total tax liability, including federal, FICA, state and IRS penalty tax liability. */
  totalTaxLiability: number,
  /** Total federal tax liability. */
  totalFederalTaxLiability: number,
  /** Total FICA tax liability, which consists of Medicare and Social Security tax. */
  totalFICATaxLiability: number,
  /** Total state tax liability. */
  totalStateTaxLiability: number,
  /** Annual taxliability. */
  annualTaxLiability: number,
  /** Total IRS penalty. */
  totalPenalty: number,
  /** Total take home pay after all taxes and penalties. */
  totalTakeHomePay: number,
  /** Total 401(k) value that's in user's 401(k) account, i.e., not yet withdrawn. */
  total401kValue: number,
  /** Total profit made from 401(k) investment. */
  totalProfit: number,
}

/***********************************************************/
/** Begin: Constants to fall back on when decoding fails. **/
/***********************************************************/
const traditional401kProfitDefaultPersonalIncome: number = 50_000;
const traditional401kProfitMinimumPersonalIncome: number = 0;
const traditional401kProfitMaximumPersonalIncome: number = 10_000_000;  // Ten million.

const traditional401kProfitDefaultPersonalContribution: number = 5_000;
const traditional401kProfitMaximumPersonalContribution: number = 19_500;
const traditional401kProfitMinimumPersonalContribution: number = 0;

const traditional401kProfitDefaultReturnRate: number = 0;
const traditional401kProfitMaximumReturnRate: number = 15;
const traditional401kProfitMinimumReturnRate: number = 0;

const traditional401kProfitDefaultCurrentAge: number = 18;
const traditional401kProfitMaximumCurrentAge: number = 59;
const traditional401kProfitMinimumCurrentAge: number = 18;

const traditional401kProfitDefaultCompanyContribution: number = 0;
const traditional401kProfitMaximumCompanyContribution: number = 19_500;
const traditional401kProfitMinimumCompanyContribution: number = 0;

const traditional401kProfitDefaultExistingContribution: number = 0;
const traditional401kProfitMaximumExistingContribution: number = 1_000_000; // One million.
const traditional401kProfitMinimumExistingContribution: number = 0;

const traditional401kProfitDefaultAgeOfEarlyWithdrawal: number = 60;
const traditional401kProfitMaximumAgeOfEarlyWithdrawal: number = 59;
/*********************************************************/
/** End: Constants to fall back on when decoding fails. **/
/*********************************************************/

/******************************/
/** Begin: Header subtitles. **/
/******************************/
const traditional401kProfitHeaderSubtitleMaritalStatus: string = 'Your marital status.';
/**
 * Returns header subtitle for user's annual personal income.
 *
 * @param personalIncome Annual personal income.
 */
function traditional401kProfitHeaderSubtitlePersonalIncome(personalIncome: number): string {
  return `Your annual personal income remains at ${formatCurrency(personalIncome)}.`;
}
/**
 * Returns header subtitle for user's residence state.
 *
 * @param residenceState User's residence state's abbreviation.
 */
function traditional401kProfitHeaderSubtitleResidenceState(residenceState: string): string {
  return `Your residence state remains ${residenceState}.`;
}
/**
 * Returns header subtitle for user's annual personal contribution.
 *
 * @param personalContribution Annual personal contribution to 401(k).
 */
function traditional401kProfitHeaderSubtitlePersonalContribution(
  personalContribution: number): string {
  return `Your annual personal contribution to 401(k) remains ${formatCurrency(personalContribution)}.`;
}
/**
 * Returns header subtitle for user's estimated return rate on 401(k) investment.
 *
 * @param returnRate Estimated annual return rate on 401(k) investment.
 */
function traditional401kProfitHeaderSubtitleReturnRate(returnRate: number): string {
  return `Estimated annual return rate on your 401(k) investment remains ${returnRate}%.`;
}
/**
 * Returns header subtitle for company's contribution to user's 401(k) account.
 *
 * @param companyContribution Annual company contribution.
 */
function traditional401kProfitHeaderSubtitleCompanyContribution(
  companyContribution: number): string {
  return `Your company's annual contribution to your 401(k) remains ${formatCurrency(companyContribution)}.`;
}
/****************************/
/** End: Header subtitles. **/
/****************************/

const traditional401kProfitRetirementAge: number = 60;

const traditional401kProfitHeaderTitle: string = 'We assume the following things don\'t change:';
const traditional401kProfitSubheaderTitle: string = 'We calculated two scenarios, one which you don\'t contribution to 401(k) and one which you do. This gives you an idea on how much cash you would end up with in both situations.';

/***********************************/
/** Begin: Titles used in tables. **/
/***********************************/
const traditional401kProfitTotalTaxableIncomeTitle: string = 'Total taxable income';
const traditional401kProfitTotalTaxLiabilityTitle: string = 'Total tax liability';
const traditional401kProfitFederalTaxLiabilityTitle: string = 'Federal tax liability';
const traditional401kProfitFICATaxLiabilityTitle: string = 'Medicare and Social Security';
const traditional401kProfitStateTaxLiabilityTitle: string = 'State tax liability';
const traditional401kProfitTakeHomePayTitle: string = 'Total take-home pay after all taxes';
const traditional401kProfitTotal401kValueTitle: string = 'Total 401(k) value';
const traditional401kProfitPenaltyTitle: string = '10% IRS penalty';
const traditional401kProfitPenaltyExplanation: string = 'To discourage people from early withdrawal, the IRS sets a 10% penalty for early withdrawal.';
const traditional401kProfitProfitTitle: string = 'Total profit';
const traditional401kProfitExplanations: Array<string> = [
  'This is an estimate on how much profit you can make compared to if you don\'t contribute to 401(k) at all.',
  'It\'s possible that this is negative, because IRS imposes a 10% penalty on 401(k) early withdrawals. If the return rate is extremely low, or your company\'s match is low, for example, this could happen.'
];
const traditional401kProfitAveragePerYearTitle: string = 'Average per year';
const traditional401kProfitRegularPersonalIncomeTitle: string = 'Regular personal income';
const traditional401kProfitAnnualTaxableIncomeTitle: string = 'Annual taxable income';
const traditional401kProfitAnnualPersonalIncomeTitle: string = 'This equals your annual personal income.';
const traditional401kProfitYearsTitle: string = 'Years';
const traditional401kProfitWithdrawal401kInRetirementDescription: string = 'You can start withdrawing from your 401(k) account at this point. The amount you withdraw is only subject to regular personal income taxes.';
const traditional401kProfitTakeHomePayWithoutWithdrawalDescription: string = 'Total taxable income - Total tax liability. This does not include what you have accumulated in your 401(k) account.';
/**
 * Returns header title for zero contribution section table.
 *
 * @param earlyWithdrawal Whether or not user wants to withdraw from their 401(k) before retirement.
 * @param ageOfEarlyWithdrawal User's inteded age of early withdrawal.
 * @param currentAge User's current age.
 */
function traditional401kProfitHeaderTitleForZeroContributionSection(
  earlyWithdrawal: boolean, ageOfEarlyWithdrawal: number, currentAge: number): string {
  if (earlyWithdrawal) {
    return `If you don't contribute to 401(k), from the age of ${currentAge} to ${ageOfEarlyWithdrawal} ...`;
  } else {
    return 'If you don\'t contribute to 401(k), by the time you retire at 59 and 1/2 years old ...';
  }
}
/**
 * Returns header title for has contribution section table.
 *
 * @param earlyWithdrawal Whether or not user wants to withdraw from their 401(k) before retirement.
 * @param ageOfEarlyWithdrawal User's inteded age of early withdrawal.
 */
function traditional401kProfitHeaderTitleForHasContributionSection(
  earlyWithdrawal: boolean, ageOfEarlyWithdrawal: number): string {
  if (earlyWithdrawal) {
    return `If you do contribute to 401(k), by the time you reach ${ageOfEarlyWithdrawal} years old ...`;
  } else {
    return 'If you do contribute to 401(k), by the time you retire at 59 and 1/2 years old ...';
  }
}
/*********************************/
/** End: Titles used in tables. **/
/*********************************/

class Traditional401kProfitComponent extends WindowResizeListenerComponent<
  any, Traditional401kProfitComponentState> {
  constructor(props: any) {
    super(props);

    // Decode query items and set current component's state.
    const queryItems: URLSearchParams = new URLSearchParams(this.props.location.search);

    const married: boolean = this.decodeBooleanValue(
      queryItems, Traditional401kFormItemType.MARITAL_STATUS);
    const personalIncome: number = this.decodeNumberValue(queryItems,
      Traditional401kFormItemType.PERSONAL_INCOME,
      traditional401kProfitDefaultPersonalIncome,
      true,
      traditional401kProfitMinimumPersonalIncome,
      traditional401kProfitMaximumPersonalIncome);
    const residenceState: string = this.decodeStringValue(queryItems,
      Traditional401kFormItemType.RESIDENCE_STATE,
      residenceStates[0].abbreviation).toUpperCase();
    const personalContribution: number = this.decodeNumberValue(queryItems,
      Traditional401kFormItemType.PERSONAL_CONTRIBUTION,
      traditional401kProfitDefaultPersonalContribution,
      true,
      traditional401kProfitMinimumPersonalContribution,
      traditional401kProfitMaximumPersonalContribution);
    const returnRate: number = this.decodeNumberValue(queryItems,
      Traditional401kFormItemType.RETURN_RATE,
      traditional401kProfitDefaultReturnRate,
      false,
      traditional401kProfitMinimumReturnRate,
      traditional401kProfitMaximumReturnRate);
    const currentAge: number = this.decodeNumberValue(
      queryItems,
      Traditional401kFormItemType.CURRENT_AGE,
      traditional401kProfitDefaultCurrentAge,
      true,
      traditional401kProfitMinimumCurrentAge,
      traditional401kProfitMaximumCurrentAge);
    const companyContribution: number = this.decodeNumberValue(queryItems,
      Traditional401kFormItemType.COMPANY_CONTRIBUTION,
      traditional401kProfitDefaultCompanyContribution,
      true,
      traditional401kProfitMinimumCompanyContribution,
      traditional401kProfitMaximumCompanyContribution);
    const existingContribution: number = this.decodeNumberValue(queryItems,
      Traditional401kFormItemType.EXISTING_CONTRIBUTION,
      traditional401kProfitDefaultExistingContribution,
      true,
      traditional401kProfitMinimumExistingContribution,
      traditional401kProfitMaximumExistingContribution);
    const ageOfEarlyWithdrawal: number = this.decodeNumberValue(queryItems,
      Traditional401kFormItemType.AGE_OF_EARLY_WITHDRAWAL,
      traditional401kProfitDefaultAgeOfEarlyWithdrawal,
      true,
      currentAge + 1,
      traditional401kProfitMaximumAgeOfEarlyWithdrawal);
    const hasEarlyWithdrawal: boolean = this.decodeBooleanValue(
      queryItems, Traditional401kFormItemType.HAS_EARLY_WITHDRAWL) &&
      (ageOfEarlyWithdrawal > currentAge) &&
      (ageOfEarlyWithdrawal <= traditional401kProfitMaximumAgeOfEarlyWithdrawal);

    this.state = {
      width: window.innerWidth,
      height: window.innerHeight,
      married,
      personalIncome,
      residenceState,
      personalContribution,
      returnRate,
      currentAge,
      companyContribution,
      existingContribution,
      hasEarlyWithdrawal,
      ageOfEarlyWithdrawal,
    };
  }

  /**
   * Decodes a boolean target from @c searchParams.
   *
   * @param searchParams Query items to decode from.
   * @param key Key of the target.
   */
  private decodeBooleanValue(searchParams: URLSearchParams, key: string): boolean {
    return searchParams.get(key.toLowerCase()) === String(true);
  }

  /**
   * Decodes a number target from @c searchParams.
   *
   * @param searchParams Query items to decode from.
   * @param key Key of the target.
   * @param defaultValue Default number value to fall back on if decoding fails.
   * @param rounded Whether or not to round the decoded value to its nearest whole number.
   * @param minimumValue Minimum value allowed. If provided, @c max(minimumValue, decodedValue) will
   *                     be returned. If @c undefined, no lower limit is enforced.
   * @param maximumValue Maximum value allowed. If provided, @c min(maximumValue, decodedValue) will
   *                     be returned. If @c undefined, no upper limit is enforced.
   */
  private decodeNumberValue(searchParams: URLSearchParams,
    key: string,
    defaultValue: number,
    rounded: boolean,
    minimumValue?: number,
    maximumValue?: number): number {
    let value: number = Number(searchParams.get(key.toLowerCase()));

    if (isNaN(value)) {
      value = defaultValue;
    }

    if (minimumValue !== undefined && maximumValue !== undefined) {
      value = Math.min(maximumValue, Math.max(minimumValue, value));
    } else if (minimumValue !== undefined) {
      value = Math.max(minimumValue, value);
    } else if (maximumValue !== undefined) {
      value = Math.min(maximumValue, value);
    }

    if (rounded) {
      return Math.floor(value + 0.5);
    } else {
      return value;
    }
  }

  /**
   * Decodes a string target from @c searchParams.
   *
   * @param searchParams Query items to decode from.
   * @param key Key of the target.
   * @param defaultValue Default string value to fall back on if decoding fails.
   */
  private decodeStringValue(
    searchParams: URLSearchParams, key: string, defaultValue: string): string {
    return searchParams.get(key.toLowerCase()) ?? defaultValue;
  }

  /**
   * Returns profit predictions table for users who don't contribution to 401(k).
   *
   * @param personalIncome User's annual pesonal income.
   * @param married Whether or not user is married, which affects user's tax brackets.
   * @param residenceState User's residence state, used to calculate their state tax.
   * @param returnRate Estimated return rate on user's 401(k) investment.
   * @param currentAge User's current age.
   * @param earlyWithdrawal Whether or not user wants to withdraw from their 401(k) before
   *                        retirement.
   * @param ageOfEarlyWithdrawal User's inteded age of early withdrawal.
   * @param existingContribution Existing 401(k) contribution. It's possible that user has
   *                             constributed to 401(k) before, but doesn't intend on contributing
   *                             anymore.
   */
  private tableForZeroContributionSection(personalIncome: number,
    married: boolean,
    residenceState: string,
    returnRate: number,
    currentAge: number,
    earlyWithdrawal: boolean,
    ageOfEarlyWithdrawal: number,
    existingContribution: number): SectionTable {
    if (earlyWithdrawal) {
      return this.tableForZeroContributionAndHasEarlyWithdrawal401kInvestment(personalIncome,
        married,
        residenceState,
        returnRate,
        currentAge,
        ageOfEarlyWithdrawal,
        existingContribution);
    } else {
      return this.tableForZeroContributionAndNoEarlyWithdrawal401kInvestment(personalIncome,
        married,
        residenceState,
        returnRate,
        currentAge,
        existingContribution);
    }
  }

  /**
   * Returns profit predictions table for users who do contribution to 401(k).
   *
   * @param personalIncome User's annual pesonal income.
   * @param married Whether or not user is married, which affects user's tax brackets.
   * @param residenceState User's residence state, used to calculate their state tax.
   * @param personalContribution User's personal annual contribution to their 401(k) investment.
   * @param returnRate Estimated return rate on user's 401(k) investment.
   * @param companyContribution User's company's annual constribution to their 401(k) account.
   * @param currentAge User's current age.
   * @param earlyWithdrawal Whether or not user wants to withdraw from their 401(k) before
   *                        retirement.
   * @param ageOfEarlyWithdrawal User's inteded age of early withdrawal.
   * @param existingContribution Existing 401(k) contribution. It's possible that user has
   *                             constributed to 401(k) before, but doesn't intend on contributing
   *                             anymore.
   */
  private tableForHasContributionSection(personalIncome: number,
    married: boolean,
    residenceState: string,
    personalContribution: number,
    returnRate: number,
    companyContribution: number,
    currentAge: number,
    earlyWithdrawal: boolean,
    ageOfEarlyWithdrawal: number,
    existingContribution: number): SectionTable {
    if (earlyWithdrawal) {
      return this.tableForHasContributionAndHasEarlyWithdrawal401kInvestment(personalIncome,
        married,
        residenceState,
        personalContribution,
        returnRate,
        companyContribution,
        currentAge,
        ageOfEarlyWithdrawal,
        existingContribution);
    } else {
      return this.tableForHasContributionAndNoEarlyWithdrawal401kInvestment(personalIncome,
        married,
        residenceState,
        personalContribution,
        returnRate,
        companyContribution,
        currentAge,
        existingContribution);
    }
  }

  /**
   * Returns profit predictions table for users who don't contribution to 401(k) and don't intend on
   * withdrawing their existing 401(k) value before they retire.
   *
   * @param personalIncome User's annual pesonal income.
   * @param married Whether or not user is married, which affects user's tax brackets.
   * @param residenceState User's residence state, used to calculate their state tax.
   * @param returnRate Estimated return rate on user's 401(k) investment.
   * @param currentAge User's current age.
   * @param existingContribution Existing 401(k) contribution. It's possible that user has
   *                             constributed to 401(k) before, but doesn't intend on contributing
   *                             anymore.
   */
  private tableForZeroContributionAndNoEarlyWithdrawal401kInvestment(personalIncome: number,
    married: boolean,
    residenceState: string,
    returnRate: number,
    currentAge: number,
    existingContribution: number): SectionTable {
    const prediction: Traditional401kProfitPrediction =
      this.predictionForZeroContributionAndNoEarlyWithdrawal401kInvestment(
        personalIncome, married, residenceState, returnRate, currentAge, existingContribution);
    const {
      totalTaxableIncome,
      years,
      totalTaxLiability,
      totalFederalTaxLiability,
      totalFICATaxLiability,
      totalStateTaxLiability,
      annualTaxLiability,
      totalTakeHomePay,
      total401kValue } = prediction;

    return {
      title: traditional401kProfitHeaderTitleForZeroContributionSection(
        false, traditional401kProfitRetirementAge, currentAge),
      items: [
        {
          title: traditional401kProfitTotalTaxableIncomeTitle,
          titleDescriptions: [],
          value: formatCurrency(totalTaxableIncome),
          valueDescriptions: [],
          subitems: [
            {
              title: traditional401kProfitAnnualTaxableIncomeTitle,
              titleDescriptions: [],
              value: formatCurrency(personalIncome),
              valueDescriptions:[
                traditional401kProfitAnnualPersonalIncomeTitle,
              ],
              subitems: [],
            },
            {
              title: traditional401kProfitYearsTitle,
              titleDescriptions: [],
              value: String(years),
              valueDescriptions: [],
              subitems: [],
            },
          ],
        },
        {
          title: traditional401kProfitTotalTaxLiabilityTitle,
          titleDescriptions: [],
          value: formatCurrency(totalTaxLiability),
          valueDescriptions: [],
          subitems: [
            {
              title: traditional401kProfitFederalTaxLiabilityTitle,
              titleDescriptions: [],
              value: formatCurrency(totalFederalTaxLiability),
              valueDescriptions: [],
              subitems: [],
            },
            {
              title: traditional401kProfitFICATaxLiabilityTitle,
              titleDescriptions: [],
              value: formatCurrency(totalFICATaxLiability),
              valueDescriptions: [],
              subitems: [],
            },
            {
              title: traditional401kProfitStateTaxLiabilityTitle,
              titleDescriptions: [],
              value: formatCurrency(totalStateTaxLiability),
              valueDescriptions: [],
              subitems: [],
            },
            {
              title: traditional401kProfitAveragePerYearTitle,
              titleDescriptions: [],
              value: formatCurrency(annualTaxLiability),
              valueDescriptions: [],
              subitems: [],
            },
          ],
        },
        {
          title: traditional401kProfitTakeHomePayTitle,
          titleDescriptions: [],
          value: formatCurrency(totalTakeHomePay),
          valueDescriptions: [
            traditional401kProfitTakeHomePayWithoutWithdrawalDescription,
          ],
          subitems: [],
        },
        {
          title: traditional401kProfitTotal401kValueTitle,
          titleDescriptions: [],
          value: formatCurrency(total401kValue),
          valueDescriptions: [
            traditional401kProfitWithdrawal401kInRetirementDescription,
          ],
          subitems: [],
        },
      ],
    };
  }

  /**
   * Calculates and returns profit predictions for users who don't contribution to 401(k) and don't
   * intend on withdrawing their existing 401(k) value before they retire.
   *
   * @param personalIncome User's annual pesonal income.
   * @param married Whether or not user is married, which affects user's tax brackets.
   * @param residenceState User's residence state, used to calculate their state tax.
   * @param returnRate Estimated return rate on user's 401(k) investment.
   * @param currentAge User's current age.
   * @param existingContribution Existing 401(k) contribution. It's possible that user has
   *                             constributed to 401(k) before, but doesn't intend on contributing
   *                             anymore.
   */
  private predictionForZeroContributionAndNoEarlyWithdrawal401kInvestment(personalIncome: number,
    married: boolean,
    residenceState: string,
    returnRate: number,
    currentAge: number,
    existingContribution: number): Traditional401kProfitPrediction {
    const years: number = traditional401kProfitRetirementAge - currentAge;

    const total401kValue: number =
      calculateTotal401kValue(existingContribution, 0, 0, years, returnRate);

    const annualTaxableIncome: number = personalIncome;
    const totalTaxableIncome: number = annualTaxableIncome * years;

    const annualFederalTaxLiability: number =
      calculateFederalTaxLiability(annualTaxableIncome, married);
    const totalFederalTaxLiability: number = annualFederalTaxLiability * years;

    const annualFICATaxLiability: number = calculateFICATaxLiability(annualTaxableIncome, married);
    const totalFICATaxLiability: number = annualFICATaxLiability * years;

    const annualStateTaxLiability: number =
      calculateStateTaxLiability(annualTaxableIncome, married, residenceState);
    const totalStateTaxLiability: number = annualStateTaxLiability * years;

    const totalTaxLiability: number =
      totalFederalTaxLiability + totalFICATaxLiability + totalStateTaxLiability;
    const annualTaxLiability: number = totalTaxLiability / years;
    const totalTakeHomePay: number = totalTaxableIncome - totalTaxLiability;

    return {
      years,
      totalRegularPersonalIncome: totalTaxableIncome,
      totalTaxableIncome,
      totalTaxLiability,
      totalFederalTaxLiability,
      totalFICATaxLiability,
      totalStateTaxLiability,
      annualTaxLiability,
      totalPenalty: 0,
      totalTakeHomePay,
      total401kValue,
      totalProfit: 0,
    };
  }

  /**
   * Returns profit predictions table for users who DO contribute to 401(k) and don't intend on
   * withdrawing their 401(k) value before they retire.
   *
   * @param personalIncome User's annual pesonal income.
   * @param married Whether or not user is married, which affects user's tax brackets.
   * @param residenceState User's residence state, used to calculate their state tax.
   * @param personalContribution User's personal annual contribution to their 401(k) investment.
   * @param returnRate Estimated return rate on user's 401(k) investment.
   * @param companyContribution User's company's annual constribution to their 401(k) account.
   * @param currentAge User's current age.
   * @param existingContribution Existing 401(k) contribution.
   */
  private tableForHasContributionAndNoEarlyWithdrawal401kInvestment(personalIncome: number,
    married: boolean,
    residenceState: string,
    personalContribution: number,
    returnRate: number,
    companyContribution: number,
    currentAge: number,
    existingContribution: number): SectionTable {
    const prediction: Traditional401kProfitPrediction =
      this.predictionForHasContributionAndNoEarlyWithdrawal401kInvestment(personalIncome,
        married,
        residenceState,
        personalContribution,
        returnRate,
        companyContribution,
        currentAge,
        existingContribution);
    const {
      totalTaxableIncome,
      years,
      totalTaxLiability,
      totalFederalTaxLiability,
      totalFICATaxLiability,
      totalStateTaxLiability,
      annualTaxLiability,
      totalTakeHomePay,
      total401kValue } = prediction;

    return {
      title: traditional401kProfitHeaderTitleForHasContributionSection(
        false, traditional401kProfitMaximumAgeOfEarlyWithdrawal),
      items: [
        {
          title: traditional401kProfitTotalTaxableIncomeTitle,
          titleDescriptions: [],
          value: formatCurrency(totalTaxableIncome),
          valueDescriptions: [],
          subitems: [
            {
              title: traditional401kProfitAnnualTaxableIncomeTitle,
              titleDescriptions: [],
              value: formatCurrency(personalIncome - personalContribution),
              valueDescriptions: [
                `${formatCurrency(personalIncome)} - ${formatCurrency(personalContribution)}, which is your annual personal income - annual 401(k) constribution.`,
              ],
              subitems: [],
            },
            {
              title: traditional401kProfitYearsTitle,
              titleDescriptions: [],
              value: String(years),
              valueDescriptions: [],
              subitems: [],
            },
          ],
        },
        {
          title: traditional401kProfitTotalTaxLiabilityTitle,
          titleDescriptions: [],
          value: formatCurrency(totalTaxLiability),
          valueDescriptions: [],
          subitems: [
            {
              title: traditional401kProfitFederalTaxLiabilityTitle,
              titleDescriptions: [],
              value: formatCurrency(totalFederalTaxLiability),
              valueDescriptions: [],
              subitems: [],
            },
            {
              title: traditional401kProfitFICATaxLiabilityTitle,
              titleDescriptions: [],
              value: formatCurrency(totalFICATaxLiability),
              valueDescriptions: [],
              subitems: [],
            },
            {
              title: traditional401kProfitStateTaxLiabilityTitle,
              titleDescriptions: [],
              value: formatCurrency(totalStateTaxLiability),
              valueDescriptions: [],
              subitems: [],
            },
            {
              title: traditional401kProfitAveragePerYearTitle,
              titleDescriptions: [],
              value: formatCurrency(annualTaxLiability),
              valueDescriptions: [],
              subitems: [],
            },
          ],
        },
        {
          title: traditional401kProfitTakeHomePayTitle,
          titleDescriptions: [],
          value: formatCurrency(totalTakeHomePay),
          valueDescriptions: [
            traditional401kProfitTakeHomePayWithoutWithdrawalDescription,
          ],
          subitems: [],
        },
        {
          title: traditional401kProfitTotal401kValueTitle,
          titleDescriptions: [],
          value: formatCurrency(total401kValue),
          valueDescriptions: [
            traditional401kProfitWithdrawal401kInRetirementDescription,
          ],
          subitems: [],
        },
      ],
    };
  }

  /**
   * Calculates and returns profit predictions for users who DO contribute to 401(k) and don't
   * intend on withdrawing their 401(k) value before they retire.
   *
   * @param personalIncome User's annual pesonal income.
   * @param married Whether or not user is married, which affects user's tax brackets.
   * @param residenceState User's residence state, used to calculate their state tax.
   * @param personalContribution User's personal annual contribution to their 401(k) investment.
   * @param returnRate Estimated return rate on user's 401(k) investment.
   * @param companyContribution User's company's annual constribution to their 401(k) account.
   * @param currentAge User's current age.
   * @param existingContribution Existing 401(k) contribution.
   */
  private predictionForHasContributionAndNoEarlyWithdrawal401kInvestment(personalIncome: number,
    married: boolean,
    residenceState: string,
    personalContribution: number,
    returnRate: number,
    companyContribution: number,
    currentAge: number,
    existingContribution: number): Traditional401kProfitPrediction {
    const years: number = traditional401kProfitRetirementAge - currentAge;

    const total401kValue: number = calculateTotal401kValue(
      existingContribution, personalContribution, companyContribution, years, returnRate);

    const annualTaxableIncome: number = personalIncome - personalContribution;
    const totalTaxableIncome: number = annualTaxableIncome * years;

    const annualFederalTaxLiability: number =
      calculateFederalTaxLiability(annualTaxableIncome, married);
    const totalFederalTaxLiability: number = annualFederalTaxLiability * years;

    const annualFICATaxLiability: number = calculateFICATaxLiability(annualTaxableIncome, married);
    const totalFICATaxLiability: number = annualFICATaxLiability * years;

    const annualStateTaxLiability: number =
      calculateStateTaxLiability(annualTaxableIncome, married, residenceState);
    const totalStateTaxLiability: number = annualStateTaxLiability * years;

    const totalTaxLiability: number =
      totalFederalTaxLiability + totalFICATaxLiability + totalStateTaxLiability;
    const annualTaxLiability: number = totalTaxLiability / years;
    const totalTakeHomePay: number = totalTaxableIncome - totalTaxLiability;

    return {
      years,
      totalRegularPersonalIncome: totalTaxableIncome,
      totalTaxableIncome,
      totalTaxLiability,
      totalFederalTaxLiability,
      totalFICATaxLiability,
      totalStateTaxLiability,
      annualTaxLiability,
      totalPenalty: 0,
      totalTakeHomePay,
      total401kValue,
      totalProfit: 0,
    };
  }

  /**
   * Returns profit predictions table for users who don't contribute to 401(k) and DO
   * intend on withdrawing their 401(k) value before they retire.
   *
   * @param personalIncome User's annual personal income.
   * @param married Whether or not user is married, which affects user's tax brackets.
   * @param residenceState User's residence state, used to calculate their state tax.
   * @param returnRate Estimated return rate on user's 401(k) investment.
   * @param currentAge User's current age.
   * @param ageOfEarlyWithdrawal User's inteded age of early withdrawal.
   * @param existingContribution Existing 401(k) contribution. It's possible that user has
   *                             constributed to 401(k) before, but doesn't intend on contributing
   *                             anymore.
   */
  private tableForZeroContributionAndHasEarlyWithdrawal401kInvestment(personalIncome: number,
    married: boolean,
    residenceState: string,
    returnRate: number,
    currentAge: number,
    ageOfEarlyWithdrawal: number,
    existingContribution: number): SectionTable {
    const prediction: Traditional401kProfitPrediction =
      this.predictionForZeroContributionAndHasEarlyWithdrawal401kInvestment(personalIncome,
        married,
        residenceState,
        returnRate,
        currentAge,
        ageOfEarlyWithdrawal,
        existingContribution);
    const {
      totalTaxableIncome,
      totalRegularPersonalIncome,
      years,
      total401kValue,
      totalTaxLiability,
      totalFederalTaxLiability,
      totalFICATaxLiability,
      totalStateTaxLiability,
      totalPenalty,
      totalTakeHomePay } = prediction;

    const formattedTotal401kValue: string = formatCurrency(total401kValue);

    return {
      title: traditional401kProfitHeaderTitleForZeroContributionSection(
        true, ageOfEarlyWithdrawal, currentAge),
      items: [
        {
          title: traditional401kProfitTotalTaxableIncomeTitle,
          titleDescriptions: [],
          value: formatCurrency(totalTaxableIncome),
          valueDescriptions: [],
          subitems: [
            {
              title: traditional401kProfitRegularPersonalIncomeTitle,
              titleDescriptions: [],
              value: formatCurrency(totalRegularPersonalIncome),
              valueDescriptions: [
                `${formatCurrency(personalIncome)} × ${years}, which is your annual personal income × years.`,
              ],
              subitems: [],
            },
            {
              title: traditional401kProfitTotal401kValueTitle,
              titleDescriptions: [],
              value: formattedTotal401kValue,
              valueDescriptions: [
                `Over the years, your existing contribution ${formatCurrency(existingContribution)} becomes ${formattedTotal401kValue}.`,
                'You selected "early withdrawal", we assume that you will cash out your 401(k) entirely.',
              ],
              subitems: [],
            },
          ],
        },
        {
          title: traditional401kProfitTotalTaxLiabilityTitle,
          titleDescriptions: [],
          value: formatCurrency(totalTaxLiability),
          valueDescriptions: [],
          subitems: [
            {
              title: traditional401kProfitFederalTaxLiabilityTitle,
              titleDescriptions: [],
              value: formatCurrency(totalFederalTaxLiability),
              valueDescriptions: [],
              subitems: [],
            },
            {
              title: traditional401kProfitFICATaxLiabilityTitle,
              titleDescriptions: [],
              value: formatCurrency(totalFICATaxLiability),
              valueDescriptions: [],
              subitems: [],
            },
            {
              title: traditional401kProfitStateTaxLiabilityTitle,
              titleDescriptions: [],
              value: formatCurrency(totalStateTaxLiability),
              valueDescriptions: [],
              subitems: [],
            },
            {
              title: traditional401kProfitPenaltyTitle,
              titleDescriptions: [
                traditional401kProfitPenaltyExplanation,
              ],
              value: formatCurrency(totalPenalty),
              valueDescriptions: [
                `${formattedTotal401kValue} × 10%, which is your total 401(k) value × 10 %.`,
              ],
              subitems: [],
            },
          ],
        },
        {
          title: traditional401kProfitTakeHomePayTitle,
          titleDescriptions: [],
          value: formatCurrency(totalTakeHomePay),
          valueDescriptions: [],
          subitems: [],
        },
      ],
    };
  }

  /**
   * Calculates and returns profit predictions for users who don't contribute to 401(k) and DO
   * intend on withdrawing their 401(k) value before they retire.
   *
   * @param personalIncome User's annual personal income.
   * @param married Whether or not user is married, which affects user's tax brackets.
   * @param residenceState User's residence state, used to calculate their state tax.
   * @param returnRate Estimated return rate on user's 401(k) investment.
   * @param currentAge User's current age.
   * @param ageOfEarlyWithdrawal User's inteded age of early withdrawal.
   * @param existingContribution Existing 401(k) contribution. It's possible that user has
   *                             constributed to 401(k) before, but doesn't intend on contributing
   *                             anymore.
   */
  private predictionForZeroContributionAndHasEarlyWithdrawal401kInvestment(personalIncome: number,
    married: boolean,
    residenceState: string,
    returnRate: number,
    currentAge: number,
    ageOfEarlyWithdrawal: number,
    existingContribution: number): Traditional401kProfitPrediction {
    const years: number = ageOfEarlyWithdrawal - currentAge;

    const total401kValue: number =
      calculateTotal401kValue(existingContribution, 0, 0, years, returnRate);
    const taxableIncomeForLastYear: number = personalIncome + total401kValue;
    const federalTaxLiabilityForLastYear: number =
      calculateFederalTaxLiability(taxableIncomeForLastYear, married);
    const FICATaxLiabilityForLastYear: number =
      calculateFICATaxLiability(taxableIncomeForLastYear, married);
    const stateTaxLiabilityForLastYear: number =
      calculateStateTaxLiability(taxableIncomeForLastYear, married, residenceState);
    const totalPenalty: number = total401kValue * 0.1;

    const annualTaxableIncomeWithoutLastYear: number = personalIncome;
    const totalTaxableIncomeWithoutLastYear: number =
      annualTaxableIncomeWithoutLastYear * (years - 1);
    const totalRegularPersonalIncome: number = totalTaxableIncomeWithoutLastYear + personalIncome;

    const annualFederalTaxLiabilityWithoutLastYear: number =
      calculateFederalTaxLiability(annualTaxableIncomeWithoutLastYear, married);
    const totalFederalTaxLiabilityWithoutLastYear: number =
      annualFederalTaxLiabilityWithoutLastYear * (years - 1);
    const totalFederalTaxLiability: number =
      totalFederalTaxLiabilityWithoutLastYear + federalTaxLiabilityForLastYear;

    const annualFICATaxLiabilityWithoutLastYear: number =
      calculateFICATaxLiability(annualTaxableIncomeWithoutLastYear, married);
    const totalFICATaxLiabilityWithoutLastYear: number =
      annualFICATaxLiabilityWithoutLastYear * (years - 1);
    const totalFICATaxLiability: number =
      totalFICATaxLiabilityWithoutLastYear + FICATaxLiabilityForLastYear;

    const annualStateTaxLiabilityWithoutLastYear: number =
      calculateStateTaxLiability(annualTaxableIncomeWithoutLastYear, married, residenceState);
    const totalStateTaxLiabilityWithoutLastYear: number =
      annualStateTaxLiabilityWithoutLastYear * (years - 1);
    const totalStateTaxLiability: number =
      totalStateTaxLiabilityWithoutLastYear + stateTaxLiabilityForLastYear;

    const totalTaxableIncome: number = totalTaxableIncomeWithoutLastYear + taxableIncomeForLastYear;
    const totalTaxLiability: number =
      totalFederalTaxLiability + totalFICATaxLiability + totalStateTaxLiability + totalPenalty;
    const totalTakeHomePay: number = totalTaxableIncome - totalTaxLiability;

    return {
      years,
      totalRegularPersonalIncome,
      totalTaxableIncome,
      totalTaxLiability,
      totalFederalTaxLiability,
      totalFICATaxLiability,
      totalStateTaxLiability,
      annualTaxLiability: totalTaxLiability / years,
      totalPenalty,
      totalTakeHomePay,
      total401kValue,
      totalProfit: 0,
    };
  }

  /**
   * Returns profit predictions table for users who contribute to 401(k) and DO intend on
   * withdrawing their 401(k) value before they retire.
   *
   * @param personalIncome User's annual personal income.
   * @param married Whether or not user is married, which affects user's tax brackets.
   * @param residenceState User's residence state, used to calculate their state tax.
   * @param personalContribution User's personal annual contribution to their 401(k) investment.
   * @param returnRate Estimated return rate on user's 401(k) investment.
   * @param companyContribution User's company's annual constribution to their 401(k) account.
   * @param currentAge User's current age.
   * @param ageOfEarlyWithdrawal User's inteded age of early withdrawal.
   * @param existingContribution Existing 401(k) contribution. It's possible that user has
   *                             constributed to 401(k) before, but doesn't intend on contributing
   *                             anymore.
   */
  private tableForHasContributionAndHasEarlyWithdrawal401kInvestment(personalIncome: number,
    married: boolean,
    residenceState: string,
    personalContribution: number,
    returnRate: number,
    companyContribution: number,
    currentAge: number,
    ageOfEarlyWithdrawal: number,
    existingContribution: number): SectionTable {
    const prediction: Traditional401kProfitPrediction =
      this.predictionForHasContributionAndHasEarlyWithdrawal401kInvestment(personalIncome,
        married,
        residenceState,
        personalContribution,
        returnRate,
        companyContribution,
        currentAge,
        ageOfEarlyWithdrawal,
        existingContribution);
    const {
      years,
      total401kValue,
      totalTaxableIncome,
      totalRegularPersonalIncome,
      totalTaxLiability,
      totalFederalTaxLiability,
      totalFICATaxLiability,
      totalStateTaxLiability,
      totalPenalty,
      totalTakeHomePay,
      totalProfit } = prediction;

    const formattedPersonalIncome: string = formatCurrency(personalIncome);
    const formattedPersonalContribution: string = formatCurrency(personalContribution);
    const formattedExistingContribution: string = formatCurrency(existingContribution);
    const formattedCompanyContribution: string = formatCurrency(companyContribution);
    const formattedTotal401kValue: string = formatCurrency(total401kValue);

    return {
      title: traditional401kProfitHeaderTitleForHasContributionSection(true, ageOfEarlyWithdrawal),
      items: [
        {
          title: traditional401kProfitTotalTaxableIncomeTitle,
          titleDescriptions: [],
          value: formatCurrency(totalTaxableIncome),
          valueDescriptions: [],
          subitems: [
            {
              title: traditional401kProfitRegularPersonalIncomeTitle,
              titleDescriptions: [],
              value: formatCurrency(totalRegularPersonalIncome),
              valueDescriptions: [
                `(${formattedPersonalIncome} - ${formattedPersonalContribution}) × ${years}.`,
                `You don't pay taxes on your annual 401(k) contribution, which is ${formattedPersonalContribution}.`,
              ],
              subitems: [],
            },
            {
              title: traditional401kProfitTotal401kValueTitle,
              titleDescriptions: [],
              value: formattedTotal401kValue,
              valueDescriptions: [
                `Existing contribution ${formattedExistingContribution}, annual personal contribution ${formattedPersonalContribution} and annual company contribution ${formattedCompanyContribution} over the years become ${formattedTotal401kValue}.`,
              ],
              subitems: [],
            }
          ],
        },
        {
          title: traditional401kProfitTotalTaxLiabilityTitle,
          titleDescriptions: [],
          value: formatCurrency(totalTaxLiability),
          valueDescriptions: [
            `This includes the tax liability on 401(k) value ${formattedTotal401kValue} due to early withdrawal.`,
          ],
          subitems: [
            {
              title: traditional401kProfitFederalTaxLiabilityTitle,
              titleDescriptions: [],
              value: formatCurrency(totalFederalTaxLiability),
              valueDescriptions: [],
              subitems: [],
            },
            {
              title: traditional401kProfitFICATaxLiabilityTitle,
              titleDescriptions: [],
              value: formatCurrency(totalFICATaxLiability),
              valueDescriptions: [],
              subitems: [],
            },
            {
              title: traditional401kProfitStateTaxLiabilityTitle,
              titleDescriptions: [],
              value: formatCurrency(totalStateTaxLiability),
              valueDescriptions: [],
              subitems: [],
            },
            {
              title: traditional401kProfitPenaltyTitle,
              titleDescriptions: [
                traditional401kProfitPenaltyExplanation,
              ],
              value: formatCurrency(totalPenalty),
              valueDescriptions: [
                `${formattedTotal401kValue} × 10%, which is your total 401(k) value × 10%.`
              ],
              subitems: [],
            },
          ],
        },
        {
          title: traditional401kProfitTakeHomePayTitle,
          titleDescriptions: [],
          value: formatCurrency(totalTakeHomePay),
          valueDescriptions: [],
          subitems: [],
        },
        {
          title: traditional401kProfitProfitTitle,
          titleDescriptions: traditional401kProfitExplanations,
          value: formatCurrency(totalProfit),
          valueDescriptions: [],
          subitems: [],
        },
      ],
    };
  }

  /**
   * Calculates and returns profit predictions for users who contribute to 401(k) and DO intend on
   * withdrawing their 401(k) value before they retire.
   *
   * @param personalIncome User's annual personal income.
   * @param married Whether or not user is married, which affects user's tax brackets.
   * @param residenceState User's residence state, used to calculate their state tax.
   * @param personalContribution User's personal annual contribution to their 401(k) investment.
   * @param returnRate Estimated return rate on user's 401(k) investment.
   * @param companyContribution User's company's annual constribution to their 401(k) account.
   * @param currentAge User's current age.
   * @param ageOfEarlyWithdrawal User's inteded age of early withdrawal.
   * @param existingContribution Existing 401(k) contribution. It's possible that user has
   *                             constributed to 401(k) before, but doesn't intend on contributing
   *                             anymore.
   */
  private predictionForHasContributionAndHasEarlyWithdrawal401kInvestment(personalIncome: number,
    married: boolean,
    residenceState: string,
    personalContribution: number,
    returnRate: number,
    companyContribution: number,
    currentAge: number,
    ageOfEarlyWithdrawal: number,
    existingContribution: number): Traditional401kProfitPrediction {
    const years: number = ageOfEarlyWithdrawal - currentAge;

    const total401kValue: number = calculateTotal401kValue(
      existingContribution, personalContribution, companyContribution, years, returnRate);
    const totalPenalty: number = total401kValue * 0.1;
    const taxableIncomeForLastYear: number = personalIncome - personalContribution + total401kValue;
    const federalTaxLiabilityForLastYear: number =
      calculateFederalTaxLiability(taxableIncomeForLastYear, married);
    const FICATaxLiabilityForLastYear: number =
      calculateFICATaxLiability(taxableIncomeForLastYear, married);
    const stateTaxLiabilityForLastYear: number =
      calculateStateTaxLiability(taxableIncomeForLastYear, married, residenceState);

    const annualTaxableIncomeWithoutLastYear: number = personalIncome - personalContribution;
    const totalTaxableIncomeWithoutLastYear: number =
      annualTaxableIncomeWithoutLastYear * (years - 1);
    const totalRegularPersonalIncome: number =
      totalTaxableIncomeWithoutLastYear + personalIncome - personalContribution;

    const annualFederalTaxLiabilityWithoutLastYear: number =
      calculateFederalTaxLiability(annualTaxableIncomeWithoutLastYear, married);
    const totalFederalTaxLiabilityWithoutLastYear: number =
      annualFederalTaxLiabilityWithoutLastYear * (years - 1);
    const totalFederalTaxLiability: number =
      totalFederalTaxLiabilityWithoutLastYear + federalTaxLiabilityForLastYear;

    const annualFICATaxLiabilityWithoutLastYear: number =
      calculateFICATaxLiability(annualTaxableIncomeWithoutLastYear, married);
    const totalFICATaxLiabilityWithoutLastYear: number =
      annualFICATaxLiabilityWithoutLastYear * (years - 1);
    const totalFICATaxLiability: number =
      totalFICATaxLiabilityWithoutLastYear + FICATaxLiabilityForLastYear;

    const annualStateTaxLiabilityWithoutLastYear: number =
      calculateStateTaxLiability(annualTaxableIncomeWithoutLastYear, married, residenceState);
    const totalStateTaxLiabilityWithoutLastYear: number =
      annualStateTaxLiabilityWithoutLastYear * (years - 1);
    const totalStateTaxLiability: number =
      totalStateTaxLiabilityWithoutLastYear + stateTaxLiabilityForLastYear;

    const totalTaxableIncome: number = totalTaxableIncomeWithoutLastYear + taxableIncomeForLastYear;
    const totalTaxLiability: number =
      totalFederalTaxLiability + totalFICATaxLiability + totalStateTaxLiability + totalPenalty;
    const totalTakeHomePay: number = totalTaxableIncome - totalTaxLiability;
    const totalTakeHomePayWithoutContribution: number =
      this.predictionForZeroContributionAndHasEarlyWithdrawal401kInvestment(personalIncome,
        married,
        residenceState,
        returnRate,
        currentAge,
        ageOfEarlyWithdrawal,
        existingContribution).totalTakeHomePay;
    const totalProfit: number = totalTakeHomePay - totalTakeHomePayWithoutContribution;

    return {
      years,
      totalRegularPersonalIncome,
      totalTaxableIncome,
      totalTaxLiability,
      totalFederalTaxLiability,
      totalFICATaxLiability,
      totalStateTaxLiability,
      annualTaxLiability: totalTaxLiability / years,
      totalPenalty,
      totalTakeHomePay,
      total401kValue,
      totalProfit,
    };
  }

  render() {
    const {
      width,
      married,
      personalIncome,
      residenceState,
      personalContribution,
      returnRate,
      currentAge,
      companyContribution,
      existingContribution,
      hasEarlyWithdrawal,
      ageOfEarlyWithdrawal } = this.state;

    const tableForZeroContributionSection: SectionTable =
      this.tableForZeroContributionSection(personalIncome,
        married,
        residenceState,
        returnRate,
        currentAge,
        hasEarlyWithdrawal,
        ageOfEarlyWithdrawal,
        existingContribution);

    const tableForHasContributionSection: SectionTable =
      this.tableForHasContributionSection(personalIncome,
        married,
        residenceState,
        personalContribution,
        returnRate,
        companyContribution,
        currentAge,
        hasEarlyWithdrawal,
        ageOfEarlyWithdrawal,
        existingContribution);

    return (
      <div style={traditional401kProfitContainerStyle(width)}>
        <div style={traditional401kProfitHeaderTitleStyle}>
          {traditional401kProfitHeaderTitle}
        </div>
        <ol>
          <li style={traditional401kProfitHeaderSubtitleStyle}>
            {traditional401kProfitHeaderSubtitleMaritalStatus}
          </li>
          <li style={traditional401kProfitHeaderSubtitleStyle}>
            {traditional401kProfitHeaderSubtitlePersonalIncome(personalIncome)}
          </li>
          <li style={traditional401kProfitHeaderSubtitleStyle}>
            {traditional401kProfitHeaderSubtitleResidenceState(residenceState)}
          </li>
          <li style={traditional401kProfitHeaderSubtitleStyle}>
            {traditional401kProfitHeaderSubtitlePersonalContribution(personalContribution)}
          </li>
          <li style={traditional401kProfitHeaderSubtitleStyle}>
            {traditional401kProfitHeaderSubtitleReturnRate(returnRate)}
          </li>
          <li style={traditional401kProfitHeaderSubtitleStyle}>
            {traditional401kProfitHeaderSubtitleCompanyContribution(companyContribution)}
          </li>
        </ol>
        <div style={traditional401kProfitSubheaderTitleStyle}>
          {traditional401kProfitSubheaderTitle}
        </div>
        <TableComponent
          table={tableForZeroContributionSection}
          style={traditional401kProfitTableForZeroContributionStyle}
        />
        <TableComponent
          table={tableForHasContributionSection}
          style={traditional401kProfitTableForHasContributionStyle}
        />
      </div>
    );
  }
}

/**
 * Style applied to global container.
 *
 * On a smaller device, content margin should be less significant than on a larger device.
 *
 * @param width Inner width of @c window.
 */
function traditional401kProfitContainerStyle(width: number): CSSProperties {
  if (isLargeWindowWidth(width)) {
    return {
      marginLeft: '20%',
      marginRight: '20%',
      marginTop: '5%',
      marginBottom: '5%',
    };
  } else {
    return {
      margin: '5%',
    };
  }
}

const traditional401kProfitHeaderTitleStyle: CSSProperties = {
  ...AppFont,
  fontSize: '180%',
  fontWeight: 'bold',
};

const traditional401kProfitHeaderSubtitleStyle: CSSProperties = {
  ...AppFont,
};

const traditional401kProfitSubheaderTitleStyle: CSSProperties = {
  ...AppFont,
};

const traditional401kProfitTableForZeroContributionStyle: CSSProperties = {
  marginTop: '1em',
};

const traditional401kProfitTableForHasContributionStyle: CSSProperties = {
  marginTop: '2em',
};

export default Traditional401kProfitComponent;