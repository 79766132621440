import { CSSProperties } from 'react';
import { federalTaxBrackets, medicareTaxBrackets, socialSecurityTaxBrackets, StateTax, StateTaxBracket, stateTaxBrackets, TaxBracket, TaxDeduction, TaxExemption } from '../resources/tax_brackets';

/**
 * Returns whether or not current window is large.
 *
 * @param width Width of container window.
 */
export function isLargeWindowWidth(width: number): boolean {
  return width >= 850;
}

export const AppFont: CSSProperties = {
  fontFamily: 'Google Sans',
};

/**
 * Returns whether or not @c text is valid.
 *
 * @param text Text to be validated.
 * @param maximum Upper character limit for @c text.
 * @param minimum Lower character limit for @c text.
 */
export function validateText(text: string, maximum: number | undefined,
  minimum: number | undefined): boolean {
  if (maximum === undefined && minimum === undefined) {
    return true;
  } else if (maximum === undefined) {
    return text.length >= (minimum ?? text.length);
  } else if (minimum === undefined) {
    return text.length <= (maximum ?? text.length);
  } else {
    return (text.length >= (minimum ?? text.length)) && (text.length <= (maximum ?? text.length));
  }
}

/**
 * Returns whether or not an email address is valid. Currently, @c false is returned at all time
 * because email input field is not being used within the app.
 *
 * @param email Email address to be validated.
 */
export function validateEmail(email: string): boolean {
  return false;
}

/**
 * Returns whether or not @c text is a valid integer.
 *
 * @param text Text to be converted to a number and then validated.
 * @param maximum Maximum integer allowed.
 * @param minimum Minimum interger allowed.
 */
export function validateInteger(text: string, maximum: number | undefined,
  minimum: number | undefined): boolean {
  const value: number = Number(text);

  if (isNaN(value) || !Number.isInteger(value)) {
    return false;
  } else if (maximum === undefined && minimum === undefined) {
    return true;
  } else if (maximum === undefined) {
    return value >= (minimum ?? value);
  } else if (minimum === undefined) {
    return value <= (maximum ?? value);
  } else {
    return (value >= (minimum ?? value)) && (value <= (maximum ?? value));
  }
}

/**
 * Returns whether or not @c text is a valid number.
 *
 * @param text Text to be converted to a number and then validated.
 * @param maximum Maximum number allowed.
 * @param minimum Minimum number allowed.
 */
export function validateNumber(text: string, maximum: number | undefined,
  minimum: number | undefined): boolean {
  const value: number = Number(text);

  if (isNaN(value)) {
    return false;
  } else if (maximum === undefined && minimum === undefined) {
    return true;
  } else if (maximum === undefined) {
    return value >= (minimum ?? value);
  } else if (minimum === undefined) {
    return value <= (maximum ?? value);
  } else {
    return (value >= (minimum ?? value)) && (value <= (maximum ?? value));
  }
}

const currencyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD'
});

/**
 * Formats @c currency to US dollar and returns the string format.
 *
 * e.g., 12345 => $12,345.00, 0.85567 => $0.86.
 *
 * @param currency Quantity of money to be formatted.
 */
export function formatCurrency(currency: number | bigint): string {
  return currencyFormatter.format(currency);
}

/**
 * Calculates and returns the total 401(k) value after @c years years.
 *
 * @param existingContribution User's existing 401(k) value.
 * @param personalContribution User's annual personal contribution to their 401(k) account.
 * @param companyContribution User's company's annual contribution to 401(k) account.
 * @param years Number of years across user's investment period.
 * @param returnRate Estimated annual return rate on user's 401(k) investment.
 */
export function calculateTotal401kValue(existingContribution: number,
  personalContribution: number,
  companyContribution: number,
  years: number,
  returnRate: number): number {
  let total401kValue: number = existingContribution;

  for (let i = 0; i < years; ++i) {
    total401kValue += (personalContribution + companyContribution);
    total401kValue *= (1 + returnRate / 100);
  }

  return total401kValue;
}

/**
 * Calculates and returns annual tax liability.
 *
 * @param income Annual taxable income.
 * @param taxBrackets Tax brackets.
 */
function calculateTaxLiability(income: number, taxBrackets: Array<TaxBracket>): number {
  let remainingTaxableIncome: number = income;
  let taxLiability: number = 0;

  for (const bracket of taxBrackets) {
    const diff: number =
      bracket.upper === null ? remainingTaxableIncome : bracket.upper - bracket.lower;
    const effective: number = Math.min(diff, remainingTaxableIncome);
    taxLiability += effective * bracket.rate / 100;
    remainingTaxableIncome -= effective;

    if (remainingTaxableIncome <= 0) {
      break;
    }
  }

  return Math.max(Math.floor(taxLiability + 0.5), 0);
}

/**
 * Calculates and returns annual federal tax liability.
 *
 * @param income Annual taxable income.
 * @param married User's marital status.
 */
export function calculateFederalTaxLiability(income: number, married: boolean): number {
  if (married) {
    return calculateTaxLiability(income, federalTaxBrackets.married);
  } else {
    return calculateTaxLiability(income, federalTaxBrackets.single);
  }
}

/**
 * Calculates and returns annual FICA tax liability.
 *
 * @param income Annual taxable income.
 * @param married User's marital status.
 */
export function calculateFICATaxLiability(income: number, married: boolean): number {
  if (married) {
    return calculateTaxLiability(income, medicareTaxBrackets.married) +
      calculateTaxLiability(income, socialSecurityTaxBrackets.married);
  } else {
    return calculateTaxLiability(income, medicareTaxBrackets.single) +
      calculateTaxLiability(income, socialSecurityTaxBrackets.single);
  }
}

/**
 * Calculates and returns tax deduction amount.
 *
 * @param base Annual taxable income.
 * @param deductions Deduction brackets.
 */
function calculateDeductionAmount(base: number, deductions: Array<TaxDeduction>): number {
  for (const bracket of deductions) {
    if (bracket.upper === null) {
      return bracket.amount;
    } else if (bracket.lower <= base && bracket.upper >= base) {
      return bracket.amount;
    }
  }

  return 0;
}

/**
 * Calculates and returns tax exemption amount.
 *
 * @param base Annual taxable income.
 * @param exemptions Exemption brackets.
 */
function calculateExemptionAmount(base: number, exemptions: Array<TaxExemption>): number {
  for (const bracket of exemptions) {
    if (bracket.upper === null) {
      return bracket.amount;
    } else if (bracket.lower <= base && bracket.upper >= base) {
      return bracket.amount;
    }
  }

  return 0;
}

/**
 * Calculates and returns user's annual state tax liability.
 *
 * @param income Annual taxable income.
 * @param married User's marital status.
 * @param residenceState User's residence state.
 */
export function calculateStateTaxLiability(
  income: number, married: boolean, residenceState: string): number {
  const stateTax: StateTax | undefined = stateTaxBrackets.get(residenceState.toUpperCase());

  if (stateTax === undefined) {
    return 0;
  }

  const bracket: StateTaxBracket = married ? stateTax.married : stateTax.single;
  const {
    deductionByFederalTax,
    maximumFederalTaxDeductionForStateTax,
    standardDeduction,
    personalExemption,
    taxBrackets } = bracket;

  let deductionAmount: number = 0;

  if (deductionByFederalTax === true) {
    if (maximumFederalTaxDeductionForStateTax !== undefined &&
      maximumFederalTaxDeductionForStateTax !== null) {
      deductionAmount += Math.min(
        calculateFederalTaxLiability(income, married), maximumFederalTaxDeductionForStateTax);
    } else {
      deductionAmount += calculateFederalTaxLiability(income, married);
    }
  }

  deductionAmount += calculateDeductionAmount(income, standardDeduction);
  deductionAmount += calculateExemptionAmount(income, personalExemption);

  return calculateTaxLiability(income - deductionAmount, taxBrackets);
}